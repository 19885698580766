import React,{ useState,useEffect } from 'react';
import { connect } from "react-redux";
import {useFormik} from "formik";
import * as Yup from 'yup';
import moment from "moment";
import {useHistory} from "react-router";
import DashboardSidebar from '../sidebar/sidebar.jsx';

import IMG01 from '../../../../assets/images/placeholderImg300x300.png';
import StickyBox from "react-sticky-box";
// import DatePicker from "react-datepicker";
import { makeStyles } from '@material-ui/core/styles';
import TextField from '@material-ui/core/TextField';
import { API_URL_ROOT } from '../../../../redux-services/actions/constance/action.constance.js';
import toaster from "../../../../utils/ReactTostify";
import { get_user_by_id_req, update_user_details_request } from '../../../../redux-services/actions/action.users/action.function.js';
const optionsRdBtn = ["Male", "Female"];
function UserProfile (props){
  // const phoneVal = /^((\\+[1-9]{1,4}[ \\-]*)|(\\([0-9]{2,3}\\)[ \\-]*)|([0-9]{2,4})[ \\-]*)*?[0-9]{3,4}?[ \\-]*[0-9]{3,4}?$/;
  const phoneVal = new RegExp(/^[6-9]{1}[0-9]{9}$/);
  const emailTest = new RegExp(/^[^\s@]+@[^\s@]+\.[^\s@]{2,}$/i);
  const dateValue = moment(new Date()).format("YYYY-MM-DD");
  const [dateOfBirth, setDateOfBirth] = useState(dateValue);
  const [firstName, setFirstName] = useState("");
  const [lastName, setLastName] = useState("");
  const [email, setEmail] = useState("");
  const [photo, setPhoto] = useState(null);
  const [photoUpdate, setPhotoUpdate] = useState(null);

  const [photoState, setPhotoState] = useState(false);
  const [mobile, setMobile] = useState("");
  const [gender, setGender] = useState("");
  // const history = useHistory();
  //  const dataValue = history.location.state
  // console.log("data passed form index to edit",dataValue);

  const getUersById = async() =>{
    if(props.user?.userId != null){
    //   const data = {
    //     "userid":props.user?.userId 
    // }
     await props.user_by_id_req(null,(error,success)=>{
        if(error){
          
          console.log("error",error);
        } 
        if(success.status == 200 ){

          setFirstName((success && success?.response && (success?.response.length!=0) && success?.response[0].first_name));
          setLastName((success && success?.response && (success?.response.length!=0) && success?.response[0].last_name));
          setPhoto(`${API_URL_ROOT}/${(success && success?.response && (success?.response.length!=0) && success?.response[0].photo)}`);
          setEmail((success && success?.response && (success?.response.length!=0) && success?.response[0].email));
          setMobile((success && success?.response && (success?.response.length!=0) && success?.response[0].mobile));
         setDateOfBirth(moment((success && success?.response && (success?.response.length!=0) && success?.response[0].date_of_birth)).format("YYYY-MM-DD"));
          if((success && success?.response && (success?.response.length!=0) && success?.response[0].gender)){
            if((success?.response[0].gender == "male") || success?.response[0].gender == "Male"){
                setGender("Male");
            }
            else if((success?.response[0].gender == "female") || (success?.response[0].gender == "Female")){
                setGender("Female");
            }
          }
          
          
          }
      })
    }
  }

  useEffect(() =>{
   
        
    getUersById();


},[])

const classes = useStyles();
const handleBack = () => {
    props.history.goBack();
    
    
   
    
  };

  const validate =      Yup.object({
    dateofbirth: Yup.string().required("Date of birth is required")
    .test("is-greater", "Date of birth should not be greater than present date", function(value) {
      return moment(value,"YYYY-MM-DD").isSameOrBefore(moment().format("YYYY-MM-DD"));
    }),
    // email: Yup.string().matches(emailTest, 'Invalid email address').required('Email must not be empty'),   
    email: Yup.string().matches(emailTest, 'Invalid email address'),   

    
    gender: Yup.string().required('Gender must not be empty'),

    photo:Yup.mixed(),
    

})
const handlePhotoUpload = (event) =>
  {
    formik.setFieldValue("photo", event.currentTarget.files[0]);
    if(event.target.files.length !== 0){
      setPhoto(URL.createObjectURL(event.target.files[0]));
      setPhotoState(true);
      setPhotoUpdate(event.currentTarget.files[0]);
    }
  }
const formik = useFormik({
    initialValues: {
        
        "dateofbirth":dateOfBirth,
        
        "email":email,
        "photo":photo,
        "gender":gender
        

    },

    validationSchema: validate,
     enableReinitialize: true,
     onSubmit: async (values) => {
        console.log("values submission", values);
        console.log("user id ==", props.user.userId);
        var dt = formik.values;

        const formData = new FormData();
        formData.append('first_name', firstName);
        formData.append('last_name', lastName);
        formData.append('mobile', mobile);
        formData.append('date_of_birth', dt.dateofbirth);
        formData.append('gender', dt.gender);
        formData.append('email', dt.email);
        if(photoState === true){
          // formData.append('photo', dt.photo);
          formData.append('photo', photoUpdate);

        }
        
        
        // formData.append('userid', props.user.userId);
        console.log("values submission formData", formData);
        

           await props.update_profile_details(formData,(error,success)=>{
            if(error){
              
              console.log("error",error);
            }    
            if(success.status == 200 ){
              toaster('success', success.message);
                // handleBack();
        getUersById();
        // window.location.reload();
           }
           });
          // await handleBack();

          //   var data = {
            
          //   first_name:firstName,
          //   last_name:lastName,
          //   mobile:mobile,
          //   date_of_birth:dt.dateofbirth,
          //   gender:dt.gender,
          //   email:dt.email,
          //   photo:dt.photo,
          //  user_id: props.user.userId,
          //      };
          //      console.log("values submission jsonData", data);

    },
    onReset: (values) => {}

})

console.log("values submission", formik.values);
const handleDateOfBirth = (event) =>{
     //const Dateset = moment(data).format("YYYY-MM-DD");
     console.log("date of birth == " ,event.target.value);
     setDateOfBirth(event.target.value);
    formik.setFieldValue("dateofbirth",event.target.value);
  }
  
  
  const handleRadioOptn = (event) =>{
      setGender(event.target.value);
    formik.setFieldValue("gender", event.target.value);
  }

//   const onChangeFirstName = (event) =>{
//     setFirstName(event.target.value);
// }

// const onBlurFirstName= (event) =>{
//   formik.setFieldValue("first_name",event.target.value);
// }

// const onChangeLastName = (event) =>{
// setLastName(event.target.value);
// }

// const onBlurLastName= (event) =>{
// formik.setFieldValue("last_name",event.target.value);
// }

// const onChangeMobile = (event) =>{
// setMobile(event.target.value);
// }

// const onBlurMobile= (event) =>{
// formik.setFieldValue("mobile",event.target.value);
// }

const onChangeEmail = (event) =>{
setEmail(event.target.value);
}

const onBlurEmail= (event) =>{
formik.setFieldValue("email",event.target.value);
}


    console.log("date of birth obtained", dateOfBirth);
    
        return(
            <div>
                <div className="breadcrumb-bar">
				<div className="container-fluid">
					<div className="row align-items-center">
						<div className="col-md-12 col-12">
							<nav aria-label="breadcrumb" className="page-breadcrumb">
								<ol className="breadcrumb">
									<li className="breadcrumb-item"><a href="/home">Home</a></li>
									<li className="breadcrumb-item active" aria-current="page">Profile Settings</li>
								</ol>
							</nav>
							<h2 className="breadcrumb-title">Profile Settings</h2>
						</div>
					</div>
				</div>
			</div>
            <div className="content">
				<div className="container-fluid">
					<div className="row">
					    <div className="col-md-5 col--lg-4 col-xl-3 theiaStickySidebar">
                        <StickyBox offsetTop={20} offsetBottom={20}>
                            <DashboardSidebar {...props}/>
                        </StickyBox>
                       </div>

                       <div className="col-md-7 col-lg-8 col-xl-9">
    <div className="card">
        <div className="card-body">
            
         
            <form onSubmit={formik.handleSubmit}>
                <div className="row form-row">
                    <div className="col-12 col-md-12">
                        <div className="form-group">
                            <div className="change-avatar">
                                <div className="profile-img">
                                { photoState?(
                      <img
            src={(photo != null)?photo:null}
            className="w-25 h-25"
            alt={"member photo"}
          />
                    ):
                    (<img src={(photo != null)?photo:IMG01} alt="User" />)

                    }
                                </div>
                                <div className="upload-img">
                                    <div className="change-photo-btn">
                                        <span><i className="fa fa-upload"></i> upload Photo</span>
                                        <input type="file" className="upload" onChange={(event) => handlePhotoUpload(event)}/>
                                    </div>
                                    <small className="form-text text-muted">Allowed JPG, GIF or PNG. Max size of 2MB</small>
                                </div>
                            </div>
                        </div>
                    </div>
                    <div className="col-12 col-md-6">
                        <div className="form-group">
                            <label>First Name</label>
                            <input 
                            type="text" 
                            className="form-control" 
                            // defaultValue="Keshav" 
                            value={firstName}
                            disabled="disabled"
                // placeholder="Enter first name ..."
                // onChange={(event) => onChangeFirstName(event)}
                //  onBlur={(event) =>onBlurFirstName(event)}
                                
                            />
                        </div>
                        {/* {
            formik.touched.first_name && formik.errors.first_name ? (
              <div
                style={{
                  color: "red"
                }}
              >
                {formik.errors.first_name}
              </div>
            ) : null} */}
                    </div>
                    <div className="col-12 col-md-6">
                        <div className="form-group">
                            <label>Last Name</label>
                            <input 
                            type="text" 
                            className="form-control" 
                            // defaultValue="Kolle" 
                            value={lastName}
                // placeholder="Enter last name"
                // onChange={(event) => onChangeLastName(event)}
                //  onBlur={(event) =>onBlurLastName(event)}
                disabled="disabled"

                            />
                        </div>
                        {/* {
            formik.touched.last_name && formik.errors.last_name ? (
              <div
                style={{
                  color: "red"
                }}
              >
                {formik.errors.last_name}
              </div>
            ) : null} */}
                    </div>
                    <div className="col-12 col-md-6">
                        <div className="form-group">
                            <label>Date of Birth</label>
                            <div className="">
                                {/* <input type="text" className="form-control datetimepicker" defaultValue="24-07-198" */}
                                {/* <DatePicker   className="form-control datetimepicker"  selected={startDate} onChange={(date) => setStartDate(date)} />     */}
                                <TextField
        id="date"
        type="date"
        defaultValue={dateOfBirth}
        value={dateOfBirth}
        className={classes.textField }
        InputLabelProps={{
          shrink: true,
        }}
        onChange={(date) => handleDateOfBirth(date)}
      />



                                
                         </div>
                        </div>
                        {
            formik.touched.dateofbirth && formik.errors.dateofbirth ? (
              <div
                style={{
                  color: "red"
                }}
              >
                {formik.errors.dateofbirth}
              </div>
            ) : null}
                    </div>
                    {/* <div className="col-12 col-md-6">
                        <div className="form-group">
                            <label>Blood Group</label>
                            <select className="form-control select">
                                <option>A-</option>
                                <option>A+</option>
                                <option>B-</option>
                                <option>B+</option>
                                <option>AB-</option>
                                <option>AB+</option>
                                <option>O-</option>
                                <option>O+</option>
                            </select>
                        </div>
                    </div> */}
                    <div className="col-12 col-md-6">
                        <div className="form-group">
                            <label>Email ID</label>
                            <input 
                            type="email" 
                            className="form-control" 
                            // defaultValue="keshav@gmail.com" 
                            value={email}
                placeholder="Enter email address ..."
                onChange={(event) => onChangeEmail(event)}
                 onBlur={(event) =>onBlurEmail(event)}

                            />
                        </div>
                        {
            formik.touched.email && formik.errors.email ? (
              <div
                style={{
                  color: "red"
                }}
              >
                {formik.errors.email}
              </div>
            ) : null}
                    </div>
                    <div className="col-12 col-md-6">
                        <div className="form-group">
                            <label>Mobile</label>
                            <input 
                            type="text" 
                            className="form-control"
                            // defaultValue="+91 9876543210" 
                            value={mobile}
                // placeholder="Enter mobile number ..." 
                // onChange={(event) => onChangeMobile(event)}
                //  onBlur={(event) =>onBlurMobile(event)}
                disabled="disabled"

                            />
                        </div>
                        {/* {
            formik.touched.mobile && formik.errors.mobile ? (
              <div
                style={{
                  color: "red"
                }}
              >
                {formik.errors.mobile}
              </div>
            ) : null} */}
                    </div>
                    
                </div>
                
                <div>
                <label>Gender</label>
                <div className="row ml-2">
                {optionsRdBtn.map((options, idxo) => (
                  <div key={idxo} className="text-secondary col-md-2" id={idxo}>    
                   
                      <input
                        className="form-check-input mr-2"
                        type="radio"
                        id={`optnRd[${idxo}]`}
                        value={options}
                        name={`optnameVal`}
                        checked={gender === options}
                        onChange={(event) => {
                                    handleRadioOptn(event);
                                  }}
                      />
                       <label>
                      {options}  
                    </label>
                  </div>
                ))}
              </div>
              </div>
        
    


                <div className="submit-section">
                    <button type="submit" className="btn btn-primary submit-btn">Save Changes</button>
                </div>
            </form>
           
            
        </div>
    </div>
</div>
                    </div>
                </div>
            </div>      
            </div>
        );
                }

                const useStyles = makeStyles((theme) => ({
                    container: {
                      display: 'flex',
                      flexWrap: 'wrap',
                    },
                    textField: {
                      marginLeft: theme.spacing(1),
                      marginRight: theme.spacing(1),
                      width: 200,
                    },
                  }));
            
                const mapDispatchToProps = (dispatch) => {
                    return {
                        
                     user_by_id_req: (data,callback) =>dispatch(get_user_by_id_req(data,callback)),
                     update_profile_details: (data,callback) =>dispatch(update_user_details_request(data,callback)),
                    };
                  };
                  const mapStateToProps = (state) => {
                    return {
                      status: state.user.status,
                      error: state.user.error,
                    user_by_id:state.user.user_by_id,
                      user: state.user.user,
                      isLoggedIn: state.user.isLoggedIn,
                      requesting: state.user.requesting,
                  
                  
                    };
                  };
export default connect(mapStateToProps, mapDispatchToProps)(UserProfile);   
        

