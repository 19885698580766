import React,{ useState, useEffect } from 'react'
import { useParams } from "react-router-dom";
import Select from "react-select";
import {useFormik} from "formik";
import * as Yup from "yup";
import {connect} from "react-redux";
import moment from "moment";
import supportImg from "../../../../../assets/images/support80.png";
import {get_all_orders_req} from "../../../../../redux-services/actions/action.order/action.function.js";
import { create_support_request } from '../../../../../redux-services/actions/action.support/action.function';
import toaster from "../../../../../utils/ReactTostify";
import {statusConstants} from "../../../../../utils/constants";
const supportTypeOptions = [
    {
      support_type_id:"1",
      support_type_name:"Payment related"
    },
    {
      support_type_id:"2",
      support_type_name:"Report related"
    },
    {
      support_type_id:"3",
      support_type_name:"Service related"
    },
    {
      support_type_id:"4",
      support_type_name:"Others"
    },
    
  ]

function CreateSupport(props) {
    let { support_id } = useParams();

    const [selectOptionsOrderId, setSelectOptionsOrderId] = useState([]);
    const [optionValueMyOrder, setOptionValueMyOrder] = useState([]);

    const [selectOptionsSupportTypeId, setSelectOptionsSupportTypeId] = useState([]);
    const [optionValueMySupportType, setOptionValueMySupportType] = useState([]);

    const [ordersData, setOrdersData] = useState([]);

    const [patientName, setPatientName] = useState("");
    const [patientId, setPatientId] = useState("");

    const [submitButton, setSubmitButton] = useState(false);


    const handleBack = () => {
        props.reloadSupport();
      };


      const getOrdersApiCall = async() =>{
    
        await props.get_all_orders(null,(error,success)=>{
         if(error){
           
           console.log("error",error);
         }    
         if(success.status == 200 ){
           if (success && success?.response.length > 0) {
             var FilteredOrderArray = [];
             let filterOrders = success?.response.filter((orderData) =>{
              const UpdatedDateTimeObtained = moment(orderData.updated_at).format("DD/MM/YYYY HH:mm:ss");
              const dateTimeValueDiff = moment().diff(
                moment(`${UpdatedDateTimeObtained}`, "DD/MM/YYYY HH:mm:ss"),
                "hours",
                false
              );

              // var d = moment.duration(dateTimeValueDiff).format("hh:mm:ss");
              // var s = d.format("hh:mm:ss");
              console.log("dateTimeValue after diiference",dateTimeValueDiff);
              
              const support_expiry_time = moment(`${success.support_expiry_time}`, "X").format("X");
              console.log("support_expiry_time format",support_expiry_time);
              //  if((orderData.status !== statusConstants.closed) && (dateTimeValueDiff < support_expiry_time)){
              //     FilteredOrderArray.push(orderData);
              //  }

              if((orderData.status != statusConstants.closed)||((orderData.status == statusConstants.closed) && (dateTimeValueDiff < support_expiry_time)))
                return orderData;
             
             })
            //  console.log("filtered values ", FilteredOrderArray);
            //  setOrdersData(FilteredOrderArray);
           
            

            const optionsOrder = filterOrders.map((myOrderSelect, idx) => ({
              order_id: myOrderSelect.orderid,
              order_name: myOrderSelect.orderid
            }));
      setSelectOptionsOrderId(optionsOrder);
      setOrdersData(filterOrders);

           }
           if (success && success?.response.length <= 0) {
             console.log("data length empty",success?.message);
            //  setNoOrdersData(success?.message);
           }
          }
          });
         
     
       }

      


     
      useEffect(() => {
       
         getOrdersApiCall();

        setSelectOptionsSupportTypeId(supportTypeOptions);
      }, []);

      console.log("order data after filteration ", ordersData);
      
      const handleOrder = (e) => {
        console.log("values of select order ", e);
        formik.setFieldValue("order_id",e.order_name);

        setOptionValueMyOrder(e);

       let filteredData = ordersData.filter((orderData) =>orderData.orderid);
       filteredData.forEach((filterValue) =>{
         setPatientName(`${filterValue.patient_first_name} ${filterValue.patient_last_name}`);
         setPatientId(`${filterValue.patient_id}`)
       })

      };

      const handleSupport = (e) => {
        console.log("values of select select support type ", e);
        formik.setFieldValue("support_type",e.support_type_name);

        setOptionValueMySupportType(e);

      
      };

      const validate = Yup.object({
        order_id:Yup.string().required('order id must not be empty'),
        support_type:Yup.string().required('Support type must not be empty'),
        description:Yup.string().required('Support type must not be empty'),
         
      });
      const formik = useFormik({
        initialValues: {
          "order_id":"",
          "support_type":"",
          "description":"",
         
        },
        validationSchema: validate,
        onSubmit: async (values) => {
          setSubmitButton(true);
            console.log("values submission", values);
            // console.log("user id ==", props.user.userId);
            var dt = formik.values;
    
                   var data = {
                    "userid":`${props.user?.userId}`,
                     "patient_id":`${patientId}`,
                    "patient_name":`${patientName}`,
                "orderid":`${dt.order_id}`,
                "support_type":`${dt.support_type}`,
                "issue_description":`${dt.description}`,
                
        
                   };
                    console.log("values submission jsonData", data);
    
    
           
    
               await props.create_support(data,(error,success)=>{
                setSubmitButton(false);
                if(error){
                  
                  console.log("error",error);
                }    
                if(success.status == 200 ){
               
                  toaster('success', success.message);
                  handleBack();
                 
               }
               });
           
          
        },
        onReset: (values) => {}
    
    })
    console.log("formik errors", formik.errors);
    return (
        <div>
        {/* <div className="breadcrumb-bar"> 
        <div className="container-fluid">
            <div className="row align-items-center">
                <div className="col-md-12 col-12">
                    <nav aria-label="breadcrumb" className="page-breadcrumb">
                        <ol className="breadcrumb">
                            <li className="breadcrumb-item"><a href="/home">Home</a></li>
                            <li className="breadcrumb-item active" aria-current="page">Support View</li>
                        </ol>
                    </nav>
                    <h2 className="breadcrumb-title">Support View</h2>
                </div>
            </div>
        </div>
    </div>*/}
    <div className="content">
        <div className="container-fluid">
            <div className="row">
<div className="col-md-12 col-lg-12 col-xl-12 mb-3">

<div className="mr-auto p-1">
                <strong> Create support </strong>
                  </div>
 

                  <div className="col-12 col-md-12">
                  <div className="form-group">
                          <div className="change-avatar">
                            <div className="profile-img">
                            <img src={supportImg} alt="support" className='mx-auto'/>
                            </div>
                            </div>
                            </div>
        
        </div>
 
  <form onSubmit={formik.handleSubmit}>
        <div className="row form-row">
        
            <div className="col-12 col-md-12">
                <div className="form-group">
                <>
                          <h4 className="card-title">Select Order Id</h4>

                          <Select
                            options={selectOptionsOrderId}
                            
                            onChange={(event) => {
                              handleOrder(event);
                            }}
                            id="exampleFormControlInput2"
                            name="select_order_id"
                            getOptionLabel={(selectOptionsOrderId) =>
                              selectOptionsOrderId["order_name"]
                            }
                            getOptionValue={(selectOptionsOrderId) =>
                              selectOptionsOrderId["order_id"]
                            }
                          />

                          
                          {formik.touched.order_id && formik.errors.order_id ? (
                            <div
                              style={{
                                color: "red"
                              }}
                              className="mb-2"
                            >
                              {formik.errors.order_id}
                            </div>
                          ) : null}
                        </>
                </div>
            </div>

            <div className="col-12 col-md-12">
                <div className="form-group">
                <>
                          <h4 className="card-title">Select Support Type</h4>

                          <Select
                            options={selectOptionsSupportTypeId}
                            
                            onChange={(event) => {
                              handleSupport(event);
                            }}
                            id="exampleFormControlInput2"
                            name="select_support_type"
                            getOptionLabel={(selectOptionsSupportTypeId) =>
                              selectOptionsSupportTypeId["support_type_name"]
                            }
                            getOptionValue={(selectOptionsSupportTypeId) =>
                              selectOptionsSupportTypeId["support_type_id"]
                            }
                          />

                          
                          {formik.touched.support_type && formik.errors.support_type ? (
                            <div
                              style={{
                                color: "red"
                              }}
                              className="mb-2"
                            >
                              {formik.errors.support_type}
                            </div>
                          ) : null}
                        </>
                </div>
            </div>
            <div className="col-12 col-md-12">
                <div className="form-group">
                    <label>Description</label>
                    
                    <textarea 
                                     
                                        id="description"
                                        className="form-control z-depth-1 service_details"
                                        rows={6}
                                        type="text"
                                        maxLength="1000"
                                        placeholder="Enter description details..."
                                        {...formik.getFieldProps("description")}
                                      />
                                       {formik.touched.description && formik.errors.description ? (
                            <div
                              style={{
                                color: "red"
                              }}
                              className="mb-2"
                            >
                              {formik.errors.description}
                            </div>
                          ) : null}
                                      <small className="text-muted">
                  {" "}
                  Max 1000 characters only
                </small>
                </div>
            </div>

            
            <div className="mx-1 row">
                        <div className="submit-section mr-3">
                          <button
                            type="submit"
                            className="btn btn-primary submit-btn"
                            disabled={submitButton}
                          >
                            Submit
                          </button>
                        </div>
                        <div className="submit-section">
                          <button
                            type="submit"
                            className="btn btn-primary submit-btn"
                            onClick={props.toggle}
                          >
                            Close
                          </button>
                        </div>
                      </div>
                      </div>
      
      </form>
 

            {/* <div className="submit-section"> 
                    <button type="button" className="btn btn-primary submit-btn"  onClick={handleBack}>Back</button>
                </div>
        </div>*/}
      
 
   
    
</div>

            </div>
        </div>
    </div>      
    </div>
);
        }


        const mapDispatchToProps = (dispatch) => {
            return {
           
            get_all_orders: (data,callback) =>dispatch(get_all_orders_req(data,callback)),
            create_support: (data,callback) =>dispatch(create_support_request(data,callback))
            
            };
          };
          const mapStateToProps = (state) => {
            return {
              
              user: state.user.user,
              
            };
          };
          
          export default connect(mapStateToProps, mapDispatchToProps)(CreateSupport);
