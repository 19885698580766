import React, { Component } from 'react';
import loginBanner from '../../assets/images/login-banner.png';
import { Formik, Form, Field, ErrorMessage } from "formik";
// import { Redirect } from 'react-router';
import  { Redirect } from 'react-router-dom' 
import { connect } from "react-redux";
// import { login } from "../../redux-services/actions/action.users/action.function";
import { login_req } from "../../redux-services/actions/action.users/action.function";
import {isLoading} from '../../redux-services/actions/action.loader/action.function.js';
import { persistor } from "../../redux-services/store/create.store.js";
import toaster from "../../utils/ReactTostify";
import './loginStyles.css'



//icon
import { Link } from 'react-router-dom';
// const mobileTest = /^((\\+[1-9]{1,4}[ \\-]*)|(\\([0-9]{2,3}\\)[ \\-]*)|([0-9]{2,4})[ \\-]*)*?[0-9]{3,4}?[ \\-]*[0-9]{3,4}?$/;
// const mobileTest = new RegExp(/^[6-9]\d{9}$/gi);
// const mobileTest = new RegExp(/^((\\+[1-9]{1,4}[ \\-]*)|(\\([0-9]{2,3}\\)[ \\-]*)|([0-9]{2,4})[ \\-]*)*?[0-9]{3,4}?[ \\-]*[0-9]{3,4}?$/);
const mobileTest = new RegExp(/^[6-9]{1}[0-9]{9}$/);
class LoginContainer extends Component {  

	constructor(props) {
		super(props);
		this.state = {
		  formValues:

		   {
			mobile: "",
			mpin: ""
		   },

		  formErrors:
		   {
			mobile: "",
			mpin: ""
		   },
		  
		  formValidity: {
			mobile: false,
			mpin: false
		  },
		  type: 'password',
		}
		this.showHide = this.showHide.bind(this);
	}
	componentDidMount(){
		document.body.classList.add('account-page');
	}
	componentWillUnmount(){
		document.body.classList.remove('account-page');
	}

	// history path
	nextPath(path) {
		this.props.history.push(path);
	  }
	handleSubmit = async (values, {
		props = this.props,
		setSubmitting
	  }) => {
	
		//  alert(JSON.stringify(values, null, 2));
		await props.login_req(values,(error,success)=>{
			if(error){
				const {status,message} = error.response;
			  console.log("error_meaasage",error.response.data.message);
			  isLoading(false);
			//   toaster('error', error.message);
			if(status == 422){
				toaster('error', "Wrong credentials or user does not exisits");
			}
			if(status == 404){
				toaster('error', "user not found!!!");
			}
		

			//   toaster('error', "Please logout with other devices to login again");
			//   isLoading(false)
			//   setTimeout(() => window.location.reload(),4000);
			if(status == 401){
				localStorage.setItem("user", "");
      localStorage.setItem("isLoggedIn", false);
	  persistor.pause();
	  persistor.flush().then(() => {
		return persistor.purge();
	  });
		localStorage.clear();
	 	// toaster('error',error.response.data.message);
		setTimeout(() => window.location.reload(),4000);
	// toaster('error',error.message);
   		
			}
			}    
			if(success== 200 ){
		   
			 this.nextPath("/users/dashboard");
			
			 
		   }
		   });
		
		setSubmitting(false);
	
		return;
		
			
	  }

	showHide(e){
		//e.preventDefault();
		//e.stopPropagation();
		this.setState({
		  type: this.state.type === 'input' ? 'password' : 'input'
		})  
	  }
	
 render(){
	
	const { error, requesting, user, status, login, } = this.props;
    console.log('i m at login page', error, requesting, '-----users----', user, '----status-----', status);
	// console.log("user value not null", user);
//     if (user != null) {
//       if (user?.userId != null) {
// 		  console.log("user value not null");
//         return (
//           <Redirect push to={{
//             pathname: "(/|/users/dashboard)"
//           }} 	 
//  />
//         )
// 	this.nextPath("/users/dashboard");
//       }
      
//     }
	console.log("user data  =======", user);
	return(

		     <div className="content">
				<div className="container-fluid">
					
					<div className="row">
						<div className="col-md-8 offset-md-2">
							
							
							<div className="account-content">
								<div className="row align-items-center justify-content-center">
									<div className="col-md-7 col-lg-6 login-left">
										<img src={loginBanner} className="img-fluid" alt="Padmashree Login" />	
									</div>
									<div className="col-md-12 col-lg-6 login-right">
										<div className="login-header">
											<h3 
											style={{textAlign:"center"}}><strong>Login</strong></h3>
										</div>

										<Formik
                      initialValues={{ mobile: "", mpin: "" }}
                      validate={values => {
                        let errors = {};
                        if (values.mobile === "") {
                          errors.mobile = "mobile number is required";
                        } else if (values.mobile.length < 10) {
                        errors.mobile = "invalid mobile number";
                      }
						else if ((!mobileTest.test(values.mobile)) && (values.mobile != "")) {
                          // errors.mobile = "Invalid mobile number format";
                          errors.mobile = "Invalid mobile number";
                        }
                        if (values.mpin === "") {
                          errors.mpin = "mpin is required";
                        } else if (values.mpin.length > 4) {
                          errors.mpin = "Invalid mpin";
                        }
                        return errors;
                      }}
                      onSubmit={this.handleSubmit}
                    >
					{({ touched, errors, isSubmitting }) => (
						
                        <React.Fragment>
                        <Form>
										
										<div className="mb-2">
											{/* <div className="form-group form-focus">
												<input type="text" className={`form-control floating ${
                                touched.mobile && errors.mobile ? "is-invalid" : ""
                                }`} maxlength="10"/>
												<label className="focus-label">Mobile Number</label>
											</div> */}
											
											<Field
                             
                              type="text"
                              name="mobile"
							  maxLength={10}
                              placeholder="Enter mobile number"
							//   pattern="[1-9]{1}[0-9]{9}"
                              className={`form-control ${
                                touched.mobile && errors.mobile ? "is-invalid" : ""
                                }`}
                            />
											<ErrorMessage
                              component="div"
                              name="mobile"
                              className="invalid-feedback"
                            />
											</div>
											<div className="mb-3">
											{/* <div className="form-group form-focus">
												<input type={this.state.type} className={`form-control floating ${
                                touched.mpin && errors.mpin ? "is-invalid" : ""
                                }`} maxlength="4" />
												<span className="float-right" onClick={this.showHide}>{(this.state.type === 'input') ? (<i class="fas fa-eye-slash"></i>):(<i class="fas fa-eye"></i>)}</span>
												<label className="focus-label">MPIN</label>
												
											</div> */}
											<Field
                             
                              type={this.state.type}
							//   pattern="^-?[0-9]\d*\.?\d*$"
							  maxLength={4}
                              name="mpin"
							  pattern="[0-9]*"
                              placeholder="Enter MPIN "
                              className={`form-control ${
                                touched.mpin && errors.mpin ? "is-invalid" : ""
                             
							}`}
							
							
							
							/>
                          <span className="float-right icon" onClick={this.showHide}>{(this.state.type === 'input') ? (<i className="fas fa-eye-slash"></i>):(<i className="fas fa-eye"></i>)}</span>

							<ErrorMessage
							component="div"
							name="mpin"
							className="invalid-feedback"



						  />
						
                           


							
											</div>
											
											{/* <Link to= "/users/dashboard"> */}
											<button className="btn btn-primary btn-block btn-lg login-btn" type="submit" disabled={isSubmitting}>Login</button>
											{/* </Link> */}
											
											</Form>
											<div className="text-right">
												<Link to="/forgot-mpin" className="forgot-link">Forgot MPIN ?</Link>
											</div>
											<div className="text-center dont-have">Don’t have an account?<Link to= "/home/register"> Register</Link></div>
											</React.Fragment>
											
					)}
										
									</Formik>
										
									</div>
								</div>
							</div>
							
								
						</div>
					</div>

				</div>

			</div>

	);
  }

}

const mapDispatchToProps = (dispatch) => {
	return {
		login_req: (data,callback) =>dispatch(login_req(data,callback)),
	};
  };
  const mapStateToProps = (state) => {
	return {
	  status: state.user.status,
	  error: state.user.error,
	  user: state.user.user,
	  isLoggedIn: state.user.isLoggedIn,
	  requesting: state.user.requesting,
  
  
	};
  };
  export default connect(mapStateToProps, mapDispatchToProps)(LoginContainer);