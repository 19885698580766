import React, {useState} from "react";
import { connect } from 'react-redux';
import {useFormik} from "formik";
import * as Yup from 'yup';
import IMG01 from "../../../../assets/images/placeholderImg300x300.png";
import {upload_prescription_req} from "../../../../redux-services/actions/action.dashboard/action.function.js";
import toaster from "../../../../utils/ReactTostify.js";


function UploadPrescription(props){
    const [uploadPrescriptionImg, setUploadPrescriptionImg] = useState(null);
    const [uploadPrescriptionImgState, setUploadPrescriptionImgState] = useState(false);
    console.log("upimage",uploadPrescriptionImg);
    console.log("upimage",uploadPrescriptionImg);



    const handleBack = () =>
    {
      console.log("hi close")
        props.reloadDashboard();
        
    }

  

    const handlePrescriptionPhotoUpload = (event) =>{
        formik.setFieldValue("prescription_photo", event.currentTarget.files[0]);
        if(event.target.files.length !== 0){
            setUploadPrescriptionImg(URL.createObjectURL(event.target.files[0]));
            setUploadPrescriptionImgState(true);
        }
      }
      const validate = Yup.object({
        prescription_name: Yup.string().required('Prescription name must not be empty'),
        prescription_photo:Yup.mixed().required('required'),
      })

      const formik = useFormik({
        initialValues: {
            "prescription_name":"",
            "prescription_photo":""
        },
        validationSchema: validate,
        onSubmit: async (values) => {
            var dt = formik.values;
            const formData = new FormData();
            formData.append('prescription_name', dt.prescription_name);
            formData.append('prescription_photo',dt.prescription_photo);
            const prescriptionPhotoURL = URL.createObjectURL(values.prescription_photo);
            localStorage.setItem("prescription_name", dt.prescription_name);
            localStorage.setItem("prescription_photo",prescriptionPhotoURL);

            console.log("image",dt.prescription_name)
            console.log("image",dt.prescription_photo)
            await props.upload_prescription(formData,(error,success)=>{
                if(error){
                  
                  console.log("error",error);
                }    
                if(success.status == 200 ){
                  // console.log("prescription_photo",success)
                  toaster("success", success.message);
                   handleBack();
                  
                }
               
                 });
        }
      })

  return (
    <div>
        <div className="content">
        <div className="container-fluid">
        <div className="col-md-12 col-lg-12 col-xl-12">
        <form onSubmit={formik.handleSubmit}>
        <div className=" form-row">
        <div className="col-12 col-md-12">
        <div className="form-group">
        <div className="change-avatar">
        <div className="profile-img">
                             
                              { uploadPrescriptionImgState?(
                      <img
            src={(uploadPrescriptionImg != null)?uploadPrescriptionImg:null}
            className="w-25 h-25"
            alt={"prescription photo"}
          />
                    ):
                    (<img src={IMG01} alt="Presction photo" />)

                    }
                            </div>

                            <div className="upload-img">
                              <div className="change-photo-btn">
                                <span>
                                  <i className="fa fa-upload"></i> Upload
                                </span>
                                <input type="file" className="upload" onChange={(event) => handlePrescriptionPhotoUpload(event)} />
                              </div>
                              <small className="form-text text-muted">
                                Allowed JPG, PNG. Max size of 2MB <span className="errorcolor ml-0">*</span>
                              </small>
                              {
            formik.touched.prescription_photo && formik.errors.prescription_photo ? (
              <div
                style={{
                  color: "red"
                }}
              >
                {formik.errors.prescription_photo}
              </div>
            ) : null}
                            </div>
        </div>
        </div>
        </div>

        <div className="col-12 col-md-12">
        <div className="form-group">
                          <label className="pr-0">Precription Name <span className="errorcolor ml-0">*</span></label> 
                          <input
                            type="text"
                            className="form-control"
                            placeholder="Enter Prescription Name ..."
                            
                            {...formik.getFieldProps("prescription_name")}
                          />
                        </div>
                        {
            formik.touched.prescription_name && formik.errors.prescription_name ? (
              <div
                style={{
                  color: "red"
                }}
              >
                {formik.errors.prescription_name}
              </div>
            ) : null}

        </div>
        
        <div className="mx-1 row">
                        <div className="submit-section mr-3">
                          <button
                            type="submit"
                            className="btn btn-primary submit-btn"
                            onClick={props.toggle}
                          >
                            Submit
                          </button>
                        </div>
                        <div className="submit-section">
                          <button
                            type="button"
                            className="btn btn-primary submit-btn"
                            // onClick={props.onClose}
                            onDoubleClick={props.toggle}
                            onClick={handleBack}
                          >
                            Close
                          </button>
                        </div>
                        </div>

        </div>
        </form>
        </div>
        </div>
        </div>

    </div>
  )
}

const mapDispatchToProps = (dispatch) =>{
    return {
        upload_prescription: (data,callback) =>dispatch(upload_prescription_req(data,callback)), 
    }
}
const mapStateToProps = (state) => ({state})



export default connect(mapStateToProps, mapDispatchToProps)(UploadPrescription)