import React,{ Component } from 'react';
import DashboardSidebar from '../sidebar/sidebar.jsx';
import { useFormik } from 'formik';
import { connect } from "react-redux";
import './style.css'
import { API_URL } from '../../../../redux-services/api/api.js';
import toaster from "../../../../utils/ReactTostify";
import { logout_req } from '../../../../redux-services/actions/action.users/action.function.js';
import { API } from '../../../../utils/networkApi.js';
import {  logout_request } from "../../../../redux-services/actions/action.logout/action.function";



import StickyBox from "react-sticky-box";
function ChangeMpin(props)
{

	const ChangeMpinApiCall = async(data) =>{
		try{
			const result = await API.post(API_URL.CHANGE_MPIN,data,API.getHeaders());
			let response = await result.data;
			console.log("response values of the change mpin",response);
			if(response.status === 200){
				verify_logout();
				// props.logout_req();
				
				// props.history.push("/login"); 
				// dispatch(push("/changeMpin"))
				// props.history.push("/ChangeMpin"); 
				toaster('success', response.message);
			}
			
		}
		catch( error){
			toaster('error', error);
		}

	}
	const verify_logout= async() =>{
		if(props.user?.userId != null){
		  
		//  await props.user_logout_req(null,(error,success)=>{
		// 	if(error){
			  
		// 	  console.log("error",error);
			
		// 		props.logout_req();
			 
	  
			  
		// 	} 
		// 	// if(success.status == 401 ){
			 
		// 	//   setFirstName((success && success.response && (success.response.length!=0) && success.response[0].first_name));
		// 	//   setLastName((success && success.response && (success.response.length!=0) && success.response[0].last_name));
		// 	//   setPhoto((success && success.response && (success.response.length!=0) && success.response[0].photo));
		// 	//   setEmail((success && success.response && (success.response.length!=0) && success.response[0].email));
			  
		// 	//   }
		// 	if(success){
		// 	//   toaster('success', success.message);
		// 	//   verify_logout();
		// 	//  }
			
		// 	}
			
		//   })

		const result = await API.get(API_URL.VERIFY_LOGOUT,API.getHeaders());
			 let response = await result.data;
			 console.log("response values of logout api ",response);
			 if(response.status === 200){
			  //  toaster('success', response.message);
        props.logout_req();
		props.history.push("/login");
			 
			 }

		}
	  }
	  
	const validate = values => {
		const errors = {}
		if (!values.oldmpin) {
			errors.oldmpin = 'Must not be empty string'
		  } else if ((values.oldmpin.length < 4) ||(values.oldmpin.length > 4) ) {
			errors.oldmpin = 'Must be 4 characters'
		  } 

          if (!values.newmpin) {
			errors.newmpin = 'Must not be empty string'
		  } else if ((values.newmpin.length < 4) || (values.newmpin.length > 4)) {
			errors.newmpin = 'Must be 4 characters'
		  } 




	  
		  if (!values.confirmmpin) {
			errors.confirmmpin = 'Must not be empty string'
		  } else if (values.newmpin !== values.confirmmpin) {
			errors.confirmmpin =  "new Mpin and confirm Mpin do not match. Please re-enter";
		  }
		 
		  return errors
		}
		const formik = useFormik({

			initialValues: {
			  oldmpin: '',
			  newmpin: '',
			  confirmmpin: ''
			},
			validate,
			onSubmit: values => {
			//   alert(JSON.stringify(values, null, 2))
			const data = {
				    "userid":props.user?.userId,
   					 "existingmpin":values.oldmpin,
    				"newmpin":values.newmpin,
    				"confirmpin":values.confirmmpin

			}
			console.log("data change mpin submitted",data);

			ChangeMpinApiCall(data);
			
			}
		
		  })
		
















	  
        return(
            <div>
                <div className="breadcrumb-bar">
				<div className="container-fluid">
					<div className="row align-items-center">
						<div className="col-md-12 col-12">
							<nav aria-label="breadcrumb" className="page-breadcrumb">
								<ol className="breadcrumb">
									<li className="breadcrumb-item"><a href="/home">Home</a></li>
									<li className="breadcrumb-item active" aria-current="page">Change MPIN</li>
								</ol>
							</nav>
							<h2 className="breadcrumb-title">Change MPIN</h2>
						</div>
					</div>
				</div>
			</div>
            <div className="content">
				<div className="container-fluid">
					<div className="row">
					    <div className="col-md-5 col-lg-4 col-xl-3 theiaStickySidebar">
						<StickyBox offsetTop={20} offsetBottom={20}>
                       		 < DashboardSidebar />
						</StickyBox>
                       </div>

                       <div className="col-md-7 col-lg-8 col-xl-9">
                                <div className="card">
                                     <div className="card-body">
                                     <form onSubmit={formik.handleSubmit}   >
												<div className="">
													<label htmlFor="oldmpin">Old MPIN</label>
													<input id="oldmpin" name="oldmpin" type="password" className="form-control"
													maxLength={4}
													onChange={formik.handleChange}
													onBlur={formik.handleBlur}
													value={formik.values.oldmpin}/>
													
												  {formik.touched.oldmpin && formik.errors.oldmpin ? <div className='error'>{formik.errors.oldmpin}</div> : null}
						                          </div>
										
												<div className="form-group">
													<label htmlFor="newmpin">New MPIN</label>
													<input  id="newmpin"    name="newmpin"    type="password" className="form-control"
													maxLength={4}

													onChange={formik.handleChange}
                                                    onBlur={formik.handleBlur}
                                                    value={formik.values.newmpin} />
                                                    
                                                    {formik.touched.newmpin && formik.errors.newmpin ? <div className='error'>{formik.errors.newmpin}</div> : null}
                                                    </div>


												<div className="form-group">
													<label htmlFor="confirmmpin">Confirm MPIN</label>
													<input  id="confirmmpin" name="confirmmpin"        type="password" className="form-control" 
													  maxLength={4}
													
													onChange={formik.handleChange}
                                                    onBlur={formik.handleBlur}
                                                    value={formik.values.confirmmpin}
													/>
												
												{formik.touched.confirmmpin && formik.errors.confirmmpin ? <div className='error'>{formik.errors.confirmmpin}</div> : null}
												</div>
												<div className="submit-section">
													<button type="submit" className="btn btn-primary submit-btn">Save Changes</button>
												</div>
											</form>
                                         
                                     </div>
                            </div>
                         </div>
                    </div>
                </div>
            </div>      
            </div>
        );
   }

   const mapDispatchToProps = (dispatch) => {
	return {
		logout_req: () =>dispatch(logout_req()),
		user_logout_req: (data,callback) =>dispatch(logout_request(data,callback)),

	};
  };
  const mapStateToProps = (state) => {
	return {
	  status: state.user.status,
	  error: state.user.error,
	  user: state.user.user,
	  isLoggedIn: state.user.isLoggedIn,
	  requesting: state.user.requesting,
  
  
	};
  };
  export default connect(mapStateToProps, mapDispatchToProps)( ChangeMpin);   
        

