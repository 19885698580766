
import React, { useState, useEffect } from "react";
//import React, {Component} from 'react';
import { Link } from "react-router-dom";
import blog1 from "../../../assets/images/blog/blog-img1.jpg";
import blog2 from "../../../assets/images/blog/blog-img2.jpg";
import blog3 from "../../../assets/images/blog/blog-img3.jpg";
import blog4 from "../../../assets/images/blog/blog-img4.jpg";
// import article from "../../../assets/images/article.jpg";
import article from "../../../assets/images/blog/blog-thumb-10.jpg"
import Slider from "react-slick";
import moment from "moment";
import { API_URL_ROOT } from '../../../redux-services/actions/constance/action.constance';
import { get_all_articles_req } from "../../../redux-services/actions/action.article/action.function.js";

import parse from 'html-react-parser';
import _truncate from "lodash/truncate";
import {useHistory} from "react-router";

import { connect } from "react-redux";
function Article(props) {
   
    const [articleData, setArticleData] = useState([]);
    const [noArticleData, setNoArticleData] = useState(null);

    const history = useHistory();
    const nextPathDataPush = (path, data) => {
        history.push({pathname: path, state: {
                data: data,
            }
        })
    }
  

    const getArticleApiCall = async () => {
        await props.get_all_articles(null, (error, success) => {
            if (error) {

                console.log("error", error);
            }
            if (success.status == 200) {
                if (success && success?.response.length > 0) {
                    setArticleData(success?.response);
                }
                if (success && success?.response.length <= 0) {
                    console.log("data length empty", success?.message);
                    setNoArticleData(success?.message);
                    setArticleData([]);
                }
            }
        });


    }

    console.log("article data from state === ",articleData);
    useEffect(() => {

        getArticleApiCall();

    }, []);

    const onClickArticle = (articleid) => {
        var data = {

            "articleid": articleid

        }

        nextPathDataPush(`/home/articles/articledetails`, data);

    }
    console.log("all article values=====", articleData);

    const settings = {
        dots: false,
        infinite: true,
        speed: 500,
        slidesToShow: 4,
        slidesToScroll: 4,
        centerPadding: '10px',
        arrows: true,
        autoplay: true,
        autoplaySpeed: 2000,
        pauseOnHover: true,
        adaptiveHeight: true,
        responsive: [
            {
                breakpoint: 1024,
                settings: {
                  slidesToShow: 2,
                  slidesToScroll: 1,
                  adaptiveHeight: true,
                  infinite: true,
                //   dots: true
                }
              },
              {
                breakpoint: 600,
                settings: {
                  slidesToShow: 2,
                  slidesToScroll: 1,
                //   initialSlide: 2,
                  adaptiveHeight: true,
                  infinite: true,
                }
              },
              {
                breakpoint: 480,
                settings: {
                  slidesToShow: 1,
                  slidesToScroll: 1,
                  adaptiveHeight: true,
                  infinite: true,
                }
              }
        ]
    };
   
    return (
        <>
            {(articleData.length == 0) ? null : (
               
                    <section className="doctors-col">
                        <div className="container">
                            <div className="row justify-content-center">
                                <div className="section-header text-center mb-4">
                                    <h6>Articles</h6>
                                </div>
                            </div>
                            <div className="row">
                                <div className="col-lg-12">
                                <div className="article-slider slider slick-initialized slick-slider">
                                <Slider {...settings}>

                                            {(articleData.length != 0) ? articleData.map((data, idx) => (

                                                <div className="article-profile-widget" onClick={() => onClickArticle(data.article_id)} style={{
                                                    cursor: "pointer",
                                                }}
                                                key={data.article_id}
                                                data-index={idx}
                                                >
                                                    <div className="article-img" onClick={() => onClickArticle(data.article_id)} style={{
                                                        cursor: "pointer",
                                                    }}>
                                                      
                                                        <img className="img-fluid" style={{maxHeight:'260px',minHeight:'260px'}}
                                                      src={(data.featured_image === null || data.featured_image === "null") ? blog1 : `${API_URL_ROOT}/${data.featured_image}`}
                                                          alt="Post"
                                                            />
                                                         {console.log("data.featured_image",data.featured_image)} 
                                                    </div>
                                                    <div className="pro-content" onClick={() => onClickArticle(data.article_id)} style={{
                                                        cursor: "pointer",
                                                        
                                                    }}>
                                                    <div className="row">
                                                    <div className="col-md-4 col-lg-4 px-0 ">
            
                                                    <p className="title" onClick={() => onClickArticle(data.article_id)} style={{
                                                        cursor: "pointer",
                                                        backgroundColor:"#3065b5",
                                                        color:"#fff",
                                                        // padding:"0.1em 0.25em",
                                                        borderRadius:"7px",
                                                        textAlign:"center"
                                                        
                                                        // justifyContent:"center"
                                                        
                                                    }}>{(data.created_at != null)?moment(data.created_at).format("DD MMM YYYY"):null}</p>
                                                    </div>
                                                    <div className="col-md-8 col-lg-8">
                                                    <h2 className="title" onClick={() => onClickArticle(data.article_id)} style={{
                                                        cursor: "pointer",
                                                    }}>{(data.title != null) ?((data.title.length > 30)?`${data.title.substring(0,30)}...`:data.title) : null}</h2>
                                                    </div>
                                                        
                                                    </div>
                                                        {/* <>
                                                         
                                                            {(((data.document_blog == null) || (data.document_blog === 'null')) ||((parse(data.document_blog) == null) || (parse(data.document_blog) == "null"))) ? null:parse(data.document_blog)}
                                                        </> */}
                                                        <>
                                                       {(data.short_description != null)? ((data.short_description.length > 30)?`${data.short_description.substring(0,30)}...`:data.short_description):""}
                                                        </>
                                                    </div>

                                                    <div onClick={() => onClickArticle(data.article_id)} style={{
                                                        cursor: "pointer",
                                                    }}>
                                                    <p onClick={() => onClickArticle(data.article_id)} style={{
                                                        color: "#e97a24",
                                                        cursor: "pointer",
                                                    }}>Read more{" "}
                                                        <i className="fa fa-arrow-circle-right" aria-hidden="true"></i>
                                                    {/* <span>
                                                    <i className="fas fa-angle-right inbtn"></i>
                                                    
                                                    </span> */}
                                                    </p>
                                                    </div>

                                                    {/* <div className="blog-btn" onClick={() => onClickArticle(data.article_id)} style={{
                                                        cursor: "pointer",
                                                    }}>
                                                        <div className="inbtn" onClick={() => onClickArticle(data.article_id)} style={{
                                                        cursor: "pointer",
                                                    }}>
                                                           <i className="fas fa-angle-right inbtn"></i>
                                                        </div>
                                                    </div> */}


                                                </div>

                                            )) : null}

                                        </Slider>

                                </div>
                                </div>
                            </div>

                        </div>

                    </section>
                
            )
            }
        </>

    );
}
const mapDispatchToProps = (dispatch) => {
    return {
        get_all_articles: (data, callback) =>
            dispatch(get_all_articles_req(data, callback)),

    };
};
const mapStateToProps = (state) => {
    return {
        status: state.user.status,
        error: state.user.error,
        all_family_member: state.familymember.all_family_member,
        all_my_address: state.myaddress.all_my_address,
        create_order: state.order.create_order,
        all_coupons: state.order.all_coupons,
        user_by_id: state.user.user_by_id,
        cart: state.cart.cartVal,
        total_cart_value: state.cart.total_cost,
        user: state.user.user,
        isLoggedIn: state.user.isLoggedIn,
        requesting: state.user.requesting
    };
};


export default connect(mapStateToProps, mapDispatchToProps)(Article);

//export default Article;

