import { put, call, select, takeEvery, takeLatest } from 'redux-saga/effects';
import { 
    create_order_request,
    create_order_success,
    create_order_error,

    verify_order_request,
    verify_order_success,
    verify_order_error,


    get_all_time_slot_req,
    get_all_time_slot_err,
    get_all_time_slot_success,

    get_all_coupons_req,
    get_all_coupons_success,
    get_all_coupons_err,

    verify_coupon_by_id_request,
    verify_coupon_by_id_success,
    verify_coupon_by_id_error,

    get_all_orders_req,
    get_all_orders_err,
    get_all_orders_success,

    get_order_details_by_order_id_req,
    get_order_details_by_order_id_err,
    get_order_details_by_order_id_success,

    delete_order_by_order_id_request,
    delete_order_by_order_id_success,
    delete_order_by_order_id_error,

    get_delivery_charges_req,
    get_delivery_charges_err,
    get_delivery_charges_success,


} from './action.function.js';
import { 
    API_URL,
    } from '../../api/api.js';
    import {API} from '../../../utils/networkApi';
    import {
      GET_ALL_TIME_SLOT_REQ,
      GET_ALL_COUPONS_REQ,
      POST_VERIFY_COUPON_BY_ID_REQ, 
        CREATE_ORDER_REQUEST,
        GET_ALL_ORDERS_REQ,
        VERIFY_ORDER_REQUEST,
        GET_ORDER_DETAILS_BY_ORDER_ID_REQ,
        DELETE_ORDER_BY_ORDER_ID_REQUEST,
        GET_DELIVERY_CHARGES_REQ

    } from './action.type';
    import toaster from "../../../utils/ReactTostify";
    import {isLoading} from '../action.loader/action.function.js';

    function* allTime_slotSaga(payload) {
      const {data,callback} =payload;
        try {
        //     const store = yield select();
        //     // const userId =  store.user.user?.userId;
        // console.log("store values ==========", store?.user?.user?.userId)
        // console.log("api url concatication =============",(API_URL.ALL_TIME_SLOT + store?.user?.user?.userId))
        yield put(isLoading(true));  
        let response = yield call(API.get,(API_URL.ALL_TIME_SLOT),API.getHeaders());
        yield put(isLoading(false));  
        const result  = response.data;
          console.log("all time_slot saga result === ", result);
          yield put(get_all_time_slot_success(result));
          callback(null,result);
          
         
        }
        catch (error) {
          yield put(get_all_time_slot_err(error));
          callback(error,null);
          yield put(isLoading(false));
        }
      }

      function* allCoupons_Saga(payload) {
        const {data,callback} =payload;
          try {
          //     const store = yield select();
          //     // const userId =  store.user.user?.userId;
          // console.log("store values ==========", store?.user?.user?.userId)
          // console.log("api url concatication =============",(API_URL.ALL_COUPONS + store?.user?.user?.userId))
          yield put(isLoading(true));  
          let response = yield call(API.get,(API_URL.ALL_COUPONS),API.getHeaders());
          yield put(isLoading(false));  
          const result  = response.data;
            console.log("all couponssaga result === ", result);
            yield put(get_all_coupons_success(result));
            callback(null,result);
            
           
          }
          catch (error) {
            yield put(get_all_coupons_err(error));
            callback(error,null);
            yield put(isLoading(false));
          }
        }


        function* verify_coupon_Saga(payload) {
          const {data,callback} =payload;
          console.log("data sent to verify coupon saga", data)
          try {
            // console.log("api url concatication =============",(API_URL.ADD_ADDRESS + store?.user?.user?.userId));
            yield put(isLoading(true));
            let response = yield call(API.post,API_URL.VALIDATE_COUPON, data,API.getHeaders());
            yield put(isLoading(false));
            const result  = response.data;
            console.log("verify coupon response saga",result);
            yield put(verify_coupon_by_id_success(result))
            callback(null,result);
          }
          catch(error) {
            yield put(verify_coupon_by_id_error(error));
            callback(error,null);
            yield put(isLoading(false));
            toaster("error", error);
          }
        }







        function* allOrdersSaga(payload) {
          const {data,callback} =payload;
            try {
                const store = yield select();
                // const userId =  store.user.user?.userId;
            console.log("store values ==========", store?.user?.user?.userId)
            yield put(isLoading(true));
              let response = yield call(API.get,API_URL.GET_ALL_ORDERS,API.getHeaders());
              yield put(isLoading(false));
              const result  = response.data;
              console.log("all orders saga result === ", result);
              yield put(get_all_orders_success(result));
              callback(null,result);
              
             
            }
            catch (error) {
              yield put(get_all_orders_err(error));
              callback(error,null);
              yield put(isLoading(false));
            }
          }
    

          function* order_details_by_order_id_Saga(payload) {
            const {data,callback} =payload;
            console.log("data sent to get order details by order id saga", data)
            try {
              // console.log("api url concatication =============",(API_URL.ADD_ADDRESS + store?.user?.user?.userId));
              yield put(isLoading(true));
              let response = yield call(API.post,API_URL.GET_ORDER_DETAILS_BY_ORDER_ID, data,API.getHeaders());
              yield put(isLoading(false));
              const result  = response.data;
              console.log("get order details by order id response saga",result);
              yield put(get_order_details_by_order_id_success(result))
              callback(null,result);
            }
            catch(error) {
              yield put(get_order_details_by_order_id_err(error));
              callback(error,null);
              yield put(isLoading(false));
              // toaster("error", error);
            }
          }
  








    function* createMy_orderSaga(payload) {
        const {data,callback} =payload;
        console.log("data sent to create order  saga", data)
        try {
          // console.log("api url concatication =============",(API_URL.ADD_ADDRESS + store?.user?.user?.userId));
          yield put(isLoading(true));
          let response = yield call(API.post,API_URL.CREATE_ORDER, data,API.getHeadersFormData());
          yield put(isLoading(false));
          const result  = response.data;
          console.log("create order response saga",result);
          yield put(create_order_success(result))
          callback(null,result);
        }

        catch(error) {
          yield put(create_order_error(error));
          callback(error,null);
          yield put(isLoading(false));
          toaster("error", error);
        }
      }

      function* verifyMy_orderSaga(payload) {
        const {data,callback} =payload;
        console.log("data sent to verify order  saga", data)
        try {
          // console.log("api url concatication =============",(API_URL.ADD_ADDRESS + store?.user?.user?.userId));
          yield put(isLoading(true));
          let response = yield call(API.post,API_URL.VERIFY_ORDER, data);
          yield put(isLoading(false));
          const result  = response.data;
          console.log("verify order response saga",result);
          // yield put(verify_order_success(result))
          callback(null,result);
        }
        catch(error) {
          // yield put(verify_order_error(error));
          callback(error,null);
          yield put(isLoading(false));
          toaster("error", error);
        }
      }


      function* deleteOrderByOrderIdSaga(payload) {
        const {data,callback} =payload;
        console.log("data sent to delete order saga", data)
        try {
          // console.log("api url concatication =============",(API_URL.ADD_ADDRESS + store?.user?.user?.userId));
          yield put(isLoading(true));
          let response = yield call(API.post,API_URL.CANCEL_ORDER_BY_ORDER_ID,data,API.getHeaders());
          yield put(isLoading(false));
          const result  = response.data;
          console.log("delete order response saga",result);
          yield put(delete_order_by_order_id_success(result))
          callback(null,result);
        }
        catch(error) {
          yield put(delete_order_by_order_id_error(error));
          callback(error,null);
          yield put(isLoading(false));
          toaster("error", error);
        }
      }


      function* deliveryChargesSaga(payload) {
        const {data,callback} =payload;
          try {
              const store = yield select();
              // const userId =  store.user.user?.userId;
          console.log("store values ==========", store?.user?.user?.userId)
          yield put(isLoading(true));
            let response = yield call(API.get,API_URL.GET_DELIVERY_CHARGES,API.getHeaders());
            yield put(isLoading(false));
            const result  = response.data;
            console.log("GET_DELIVERY_CHARGES saga result === ", result);
            yield put(get_delivery_charges_success(result));
            callback(null,result);
            
           
          }
          catch (error) {
            yield put(get_delivery_charges_err(error));
            callback(error,null);
            yield put(isLoading(false));
          }
        }


      export default function* my_orderSaga() { 
        yield takeEvery(GET_ALL_TIME_SLOT_REQ,allTime_slotSaga );
        yield takeEvery(GET_ALL_COUPONS_REQ, allCoupons_Saga);
        yield takeEvery( POST_VERIFY_COUPON_BY_ID_REQ, verify_coupon_Saga);
        
        yield takeEvery( CREATE_ORDER_REQUEST, createMy_orderSaga);
        yield takeEvery( GET_ALL_ORDERS_REQ, allOrdersSaga);

        yield takeEvery( GET_ORDER_DETAILS_BY_ORDER_ID_REQ, order_details_by_order_id_Saga);

        yield takeEvery( VERIFY_ORDER_REQUEST, verifyMy_orderSaga);

        yield takeEvery( DELETE_ORDER_BY_ORDER_ID_REQUEST, deleteOrderByOrderIdSaga);

        yield takeEvery( GET_DELIVERY_CHARGES_REQ, deliveryChargesSaga);
        
    } 