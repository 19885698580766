export const config_file = {
  development: {
    
    // DOMAIN_NAME: "http://localhost:3000",
    //  API_DOMAIN_NAME: "http://localhost:8080",
    DOMAIN_NAME: "http://127.0.0.1:3000",
    // API_DOMAIN_NAME: "https://lab.landclues.com/backend",
    //  API_DOMAIN_NAME: "https://pathocare.webschedio.in/backend",
    API_DOMAIN_NAME: " https://lab.landclues.com/backend",
    RAZORPAY_KEY_ID_TEST:"rzp_test_JF3lHmxMoFZ9hi",
    RAZORPAY_KEY_ID_LIVE:"rzp_live_zqOWMOKW6jnxTi",
    GOOGLE_MAP_API_KEY:"AIzaSyC-uu7hWY6Nx9AqvHM9sTmEG6w9qeJgZAg",
    REACT_APP_VAPID_KEY:"BDuEKw3R2SC1qYt2yNerv5SohudHhLiO0BaksLkjiCDUqr33kVNmp5pWj1qSBmkfxAQpn7d3Hp7ps9moZnmx0_g"
   
  },
  production: {
    
    // DOMAIN_NAME: "http://localhost:3000",
    // API_DOMAIN_NAME: "http://localhost:4000",
    DOMAIN_NAME: "http://127.0.0.1:3000",
    // API_DOMAIN_NAME: "https://lab.landclues.com/backend",
    //  API_DOMAIN_NAME: "https://pathocare.webschedio.in/backend",
    API_DOMAIN_NAME: " https://lab.landclues.com/backend",
    RAZORPAY_KEY_ID_TEST:"rzp_test_JF3lHmxMoFZ9hi",
    RAZORPAY_KEY_ID_LIVE:"rzp_live_zqOWMOKW6jnxTi",
    GOOGLE_MAP_API_KEY:"AIzaSyC-uu7hWY6Nx9AqvHM9sTmEG6w9qeJgZAg",
    REACT_APP_VAPID_KEY:"BDuEKw3R2SC1qYt2yNerv5SohudHhLiO0BaksLkjiCDUqr33kVNmp5pWj1qSBmkfxAQpn7d3Hp7ps9moZnmx0_g"
  },
  staging: {
    // DOMAIN_NAME: "https://your.xyz.com",
    // API_DOMAIN_NAME: "https://alpha.xyz.com",
    DOMAIN_NAME: "http://127.0.0.1:3000",
    // API_DOMAIN_NAME: "https://lab.landclues.com/backend",
    //  API_DOMAIN_NAME: "https://pathocare.webschedio.in/backend",
    API_DOMAIN_NAME: " https://lab.landclues.com/backend",
    RAZORPAY_KEY_ID_TEST:"rzp_test_JF3lHmxMoFZ9hi",
    RAZORPAY_KEY_ID_LIVE:"rzp_live_zqOWMOKW6jnxTi",
    GOOGLE_MAP_API_KEY:"AIzaSyC-uu7hWY6Nx9AqvHM9sTmEG6w9qeJgZAg",
    REACT_APP_VAPID_KEY:"BDuEKw3R2SC1qYt2yNerv5SohudHhLiO0BaksLkjiCDUqr33kVNmp5pWj1qSBmkfxAQpn7d3Hp7ps9moZnmx0_g"
  },
  test: {
    // DOMAIN_NAME: "http://localhost:3000",
    // API_DOMAIN_NAME: "http://localhost:4000",
    DOMAIN_NAME: "http://127.0.0.1:3000",
    // API_DOMAIN_NAME: "https://lab.landclues.com/backend",
    // API_DOMAIN_NAME: "https://pathocare.webschedio.in/backend",
    API_DOMAIN_NAME: " https://lab.landclues.com/backend",

    RAZORPAY_KEY_ID_TEST:"rzp_test_JF3lHmxMoFZ9hi",
    RAZORPAY_KEY_ID_LIVE:"rzp_live_zqOWMOKW6jnxTi",
    GOOGLE_MAP_API_KEY:"AIzaSyC-uu7hWY6Nx9AqvHM9sTmEG6w9qeJgZAg",
    REACT_APP_VAPID_KEY:"BDuEKw3R2SC1qYt2yNerv5SohudHhLiO0BaksLkjiCDUqr33kVNmp5pWj1qSBmkfxAQpn7d3Hp7ps9moZnmx0_g"
  },
};
