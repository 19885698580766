import React, { useState, useEffect } from "react";
import {Link} from 'react-router-dom';
import Lightbox from 'react-image-lightbox';
import { Tabs, Tab,Tooltip, OverlayTrigger } from 'react-bootstrap';
import { connect } from "react-redux";
import {useHistory} from "react-router";
import packagesImage from "../../../../assets/images/packages.png";
// import { Modal} from 'react-bootstrap';
// import Icon from '@material-ui/core/Icon';

import { IMG01, IMG02, IMG03, IMG04, IMG05, IMG06, IMG07, IMG08 } from './img';

import img1 from "../../../../assets/images/features/feature-01.jpg";
import img2 from "../../../../assets/images/features/feature-02.jpg";
import img3 from "../../../../assets/images/features/feature-03.jpg";
import img4 from "../../../../assets/images/features/feature-04.jpg";

const images = [img1, img2, img3, img4];
function PackageDetails(props) {

	const [key,setKey]= useState(1);
	const [activeModal,setActiveModal]= useState(0);
		

	const handleSelect = (key) => {

		setKey(key)
	}

	const openModal= (id) => {
		setActiveModal(id);
	  
	}
	const handleCloseModal = () => {
		setActiveModal(false); 
	  };

	  const history = useHistory();
	  const nextPath = (path,data) => {
		history.push({
		  pathname: path,
		  state:data
		});
	  };


	  const dataValue = history.location.state
	  console.log("data passed===",dataValue);
		return (

			<div>

				<div className="breadcrumb-bar">
					<div className="container-fluid">
						<div className="row align-items-center">
							<div className="col-md-12 col-12">
								<nav aria-label="breadcrumb" className="page-breadcrumb">
									<ol className="breadcrumb">
										<li className="breadcrumb-item"><Link to="/home">Home</Link></li>
										<li className="breadcrumb-item active" aria-current="page">Package Details</li>
									</ol>
								</nav>
								<h2 className="breadcrumb-title">Package Details</h2>
							</div>
						</div>
					</div>
				</div>

				<div className="content">
					


					<div className="card">
        <div className="card-body">
            <div className="doctor-widget">
                <div className="doc-info-left">
                    <div className="doctor-img">
                    <div>
                            <img src={packagesImage} className="img-fluid" alt="User" />
                        </div>
                    </div>
                    <div className="doc-info-cont">
                        <h4 className="doc-name">{dataValue.package_name}</h4>
                        <p className="doc-speciality">{dataValue.short_description}</p>
                     
                        <div className="clinic-details">
                            
                            <div>
   
                            
                            </div> 
                           
                        </div>
                     
                    </div>
                </div>
                <div className="doc-info-right">
                    <div className="clini-infos">
                        <ul>
                           
                            <li><i className="far fa-money-bill-alt"></i> {(dataValue.package_cost != null)? `₹ ${dataValue.package_cost}`:"None"}
                            <OverlayTrigger overlay={<Tooltip id="tooltip-disabled">{(dataValue.package_cost != null)? `₹ ${dataValue.package_cost}`:"None"}</Tooltip>}>
                                                    <span className="d-inline-block">
                                                    <i className="fas fa-info-circle"></i>
                                                    </span>
                                            </OverlayTrigger></li>
                        </ul>
                    </div>
                    <div className="clinic-booking">
                   
                        {/* <Link to="/home" className="apt-btn">Add to Cart</Link> */}
                    </div>
                </div>
            </div>
        
    </div>
						<div className="card">
							<div className="card-body pt-0 user-tabs">
								<Tabs
									className="tab-view"
									activeKey={key}
									onSelect={handleSelect}
									id="controlled-tab-example"
								>
									<Tab className="nav-item" eventKey={1} title="Description">
										<div className="row">
											<div className="col-md-12 col-lg-9">


												<div className="widget about-widget">
													<h4 className="widget-title">About Package</h4>
													<p>{dataValue.description}</p>
													<h5 className="text-primary">Services included</h5>
													
														{
															
															(dataValue.services && dataValue.services.length > 0)?(
																(dataValue.services.map((services, idx) =>(

																	<ul key={idx}>
																		<li>
																		<p>{services.service_name}</p>
																		</li>
																	</ul>
																)))
															):null
														}
													
												</div>
											</div>
										</div>
									</Tab>
									<Tab className="nav-item" eventKey={2} title="Prerequisits">
										<div>
											<div className="location-list">
												<div className="row">


													<div className="col-md-6">
													<div className="service-list">
													<h4>Prerequisits needed for this package</h4>
													<ul className="clearfix">
													<li>{dataValue.pre_requisites}</li>
														
													</ul>
												</div>
														</div>
												</div>
											</div>

										</div>

									</Tab>
									
									
								</Tabs>

							</div>
						</div>

					</div>
				</div>
				
					
			</div>

		);

	
}

export default PackageDetails;