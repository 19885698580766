import { put, call, select, takeEvery, takeLatest } from 'redux-saga/effects';
import { 
    create_book_on_call_request,
    create_book_on_call_success,
    create_book_on_call_error,
    get_all_prescriptions_req,
    get_all_prescriptions_err,
    get_all_prescriptions_success,

    upload_prescription_req,
    upload_prescription_err,
    upload_prescription_success,
} from './action.function.js';
import { 
    API_URL,
    } from '../../api/api.js';
    import {API} from '../../../utils/networkApi';
    import {
   
        CREATE_BOOK_ON_CALL_REQUEST,
        GET_ALL_PRESCRIPTIONS_REQ,
        UPLOAD_PRESCRIPTION_REQ,
        

    } from './action.type';
    import toaster from "../../../utils/ReactTostify";
    import {isLoading} from '../action.loader/action.function.js';

    function* createBookOnCallSaga(payload) {
        const {data,callback} =payload;
        console.log("data sent to create book on call saga", data)
        try {
          
          // if(data == null){
          //   let response = yield call(API.post,API_URL.BOOK_ON_CALL,API.getHeaders());
          //   const result  = response.data;
          //   console.log("create book on call response saga",result);
          //   yield put(create_book_on_call_success(result))
          //   callback(null,result);
          // } else{
          //   let response = yield call(API.post,API_URL.BOOK_ON_CALL,data,API.getHeaders());
          //   const result  = response.data;
          //   console.log("create book on call response saga",result);
          //   yield put(create_book_on_call_success(result))
          //   callback(null,result);
          // }
          yield put(isLoading(true));
        let response = yield call(API.post,API_URL.BOOK_ON_CALL,data,API.getHeadersFormData());
        yield put(isLoading(false));
        const result  = response.data;
        console.log("create book on call response saga",result);
        yield put(create_book_on_call_success(result))
        callback(null,result);
         
         
        }
        catch(error) {
          callback(error,null);
          yield put(isLoading(false));
          yield put(create_book_on_call_error(error));
          
        // toaster("error", error);
        }
      }


      function* allPrescriptionsSaga(payload) {
        const {data,callback} =payload;
          try {
              const store = yield select();
              // const userId =  store.user.user?.userId;
          console.log("store values ==========", store?.user?.user?.userId)
          yield put(isLoading(true));
            let response = yield call(API.get,API_URL.GET_ALL_PRESCRIPTIONS,API.getHeaders());
            yield put(isLoading(false));
            const result  = response.data;
            console.log("all prescriptions saga result === ", result);
            yield put(get_all_prescriptions_success(result));
            callback(null,result);
            
           
          }
          catch (error) {
            yield put(get_all_prescriptions_err(error));
            callback(error,null);
            yield put(isLoading(false));
          }
        }


        function* uploadPrescriptionSaga(payload) {
          const {data,callback} =payload;
            try {
                const store = yield select();
                // const userId =  store.user.user?.userId;
            console.log("store values ==========", data)
            console.log("store values ==========", payload)
            yield put(isLoading(true));
              let response = yield call(API.post,API_URL.UPLOAD_PRESCRIPTION,data,API.getHeadersFormData());
              yield put(isLoading(false));
              const result  = response.data;
              console.log("upload prescription saga result === ", result);
              yield put(upload_prescription_success(result));
              // localStorage.setItem("token", user.token);
              callback(null,result);
              
             
            }
            catch (error) {
              yield put(upload_prescription_err(error));
              callback(error,null);
              console.log("upload_prescription_",error)
              yield put(isLoading(false));
            }
          }
  


 export default function* dashboardSaga() { 
    yield takeEvery(CREATE_BOOK_ON_CALL_REQUEST, createBookOnCallSaga);
    yield takeEvery(GET_ALL_PRESCRIPTIONS_REQ, allPrescriptionsSaga); 
     yield takeEvery(UPLOAD_PRESCRIPTION_REQ, uploadPrescriptionSaga); 
    } 