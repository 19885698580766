import React,{ useState, useEffect } from 'react'
import { useParams } from "react-router-dom";
import {useHistory} from "react-router";
import {useFormik} from "formik";
import Select from "react-select";
import * as Yup from 'yup';
import DashboardSidebar from '../../sidebar/sidebar.jsx';
import IMG01 from '../../../../../assets/images/patients/patient.jpg';
import StickyBox from "react-sticky-box";
import MapView from "../../../../../utils/MapView";
import { Marker } from 'google-maps-react';
//import Iconmap from "../assets/images/marker.png";
//import Iconmap from "../../../../../assets/images/marker.png"

function MyAddressView(props) {
  const history = useHistory();
  const dataValue = history.location.state;
 console.log("dataValues passed to view address",dataValue);
 
  const [mapViewBtn, setMapViewBtn] = useState(false);
 // console.log("latitude",lat)
   let latitudeInitialValue = (dataValue.latitude != null) ? dataValue.latitude:12.972442;

   console.log("dataValueLat",latitudeInitialValue);
 const [lat,setLat] = useState(latitudeInitialValue);
 
 //console.log("longitude",lng)
   let LongitudeInitialValue = (dataValue.longitude != null) ? dataValue.longitude:77.530376;
 
   console.log("dataValueLng",LongitudeInitialValue);
   const [lng,setLng] = useState(LongitudeInitialValue);
  
   
  const [formattedAddress, setFormattedAddress] = useState("")
  const [memberName, setMemberName] = useState("");
  const [stateCnt, setStateCnt] = useState("");
  const [pinCode, setPinCode] = useState("");
  const [houseNumber, setHouseNumber] = useState("");
  const [streetName, setStreetName] = useState("");
  const [area, setArea] = useState("");
  const [city, setCity] = useState("");
   const [disabled,setdisabled]=useState(false);
  
   const [cityName, setCityName] = useState("");
   const [areaName, setAreaName] = useState("");
   
const [optionValueAllAreaByCityId, setOptionValueAllAreaByCityId] = useState([]);
const [allAreaByCityIdData, setAllAreaByCityIdData] = useState([]);
const [selectOptionsAllCity, setSelectOptionsAllCity] = useState([]);
const [selectOptionsAllAreaByCityId, setSelectOptionsAllAreaByCityId] = useState([]);
const [optionValueAllCity, setOptionValueAllCity] = useState([]); 
// const phoneVal = /^((\\+[1-9]{1,4}[ \\-]*)|(\\([0-9]{2,3}\\)[ \\-]*)|([0-9]{2,4})[ \\-]*)*?[0-9]{3,4}?[ \\-]*[0-9]{3,4}?$/;
const phoneVal = new RegExp(/^[6-9]{1}[0-9]{9}$/);

  const setLatitiudeFnc = (lat) => setLat(lat);
  console.log("setlatitude",lat )
  console.log("setlongitude",lng)
  console.log("intialvalue",latitudeInitialValue)
  console.log("intialvalue",LongitudeInitialValue)
  const setLongitudeFnc = (lng) => setLng(lng);
  const formattedAddressFnc = (formattedAddr) =>
  setFormattedAddress(formattedAddr);

    const areaFnc = (areaObtained) => setArea(areaObtained);
    const streetNameFnc = (streetNameObtained) =>setStreetName(streetNameObtained);
    const stateCntFnc = (stateCntObatined) =>setStateCnt(stateCntObatined);
    const pinCodeFnc = (pinCodeObtained) =>setPinCode(pinCodeObtained);
    const houseNumberFnc = (houseNumberObtained) =>setHouseNumber(houseNumberObtained);
  

    const [addressType, setAddressType] = useState("");
    const optionsRdBtn = ["Home", "Office"];
   

    const onChangeMemberName = (event) =>{
      setMemberName(event.target.value);
  }
  const onBlurMemberName= (event) =>{
    formik.setFieldValue("member_name",event.target.value);
}



    const validate = Yup.object({
      member_name:Yup.string()
      .max(20,'Must be 20 character or less')
      .required('Name must not be empty'),
        house_number:Yup.string()
        .max(10,'Must be 10 character or less')
        .required('House Number must not be empty'),
        street_name:Yup.string()
        .max(20,'Must be 20 character or less')
        .required('Street Name must not be empty'),
        area:Yup.string()
        .max(100,'Must be 100 character or less')
        .required('Area must not be empty'),
        landmark:Yup.string()
        .max(100,'Must be 100 character or less')
        .required('Landmark must not be empty'),
        city:Yup.string()
        .max(20,'Must be 20 character or less')
        .required('City must not be empty'),
        state:Yup.string()
        .max(20,'Must be 20 character or less')
        .required('State must not be empty'),
        pincode:Yup.string()
        .min(6,'Pincode should include 6 character')
        .max(6,'Pincode allowed only 6 character')
        .required('Pincode must not be empty'),
        address_type:Yup.string().required('Address type must not be empty'),
        mobile:Yup.string().matches(phoneVal, 'phone number is not valid')
        .min(10, 'Mobile number should include 10 character')
        .max(10, 'Mobile number allowed only 10 character')
        .required('mobile number required'),
    });

    const formik = useFormik({
      initialValues: {
        member_name: "",
        house_number: houseNumber,
        street_name: streetName,
        area: areaName,
        landmark: "",
        city:cityName,
        state: stateCnt,
        pincode: pinCode,
        address_type: "",
        mobile: ""
      },
      enableReinitialize: true,
      validationSchema: validate,
      onSubmit: async (values) => {
        console.log("values submission", values);
        console.log("user id ==", props.user.userId);
        var dt = formik.values;
  
        var data = {
          userid: props.user.userId,
          member_name: dt.member_name,
          house_number: dt.house_number,
          street_name: dt.street_name,
          area: dt.area,
          landmark: dt.landmark,
          city: dt.city,
          state: dt.state,
          pincode: dt.pincode,
          address_type: dt.address_type,
          mobile: dt.mobile,
          latitude: lat, 
          longitude: lng
        };
        console.log("values submission jsonData", data);
  
       
      },
      onReset: (values) => {}
    });


    console.log("data passed form index to view",dataValue);
    useEffect(() => {
      
        if((dataValue.address_type == "home" ) || dataValue.address_type == "Home"){
            setAddressType("Home");
        }
        else if((dataValue.address_type == "office") || (dataValue.address_type == "Office")){
            setAddressType("Office");
        }
        else if((dataValue.address_type == "others") || (dataValue.address_type == "Others")){
            setAddressType("Others");
        }
      }, []);
      const handleClick = () =>{
       setdisabled(true)
      }
    const handleBack = () => {
        props.history.goBack();
      };
      
    return (
        <div>
        <div className="breadcrumb-bar">
        <div className="container-fluid">
            <div className="row align-items-center">
                <div className="col-md-12 col-12">
                    <nav aria-label="breadcrumb" className="page-breadcrumb">
                        <ol className="breadcrumb">
                            <li className="breadcrumb-item"><a href="/home">Home</a></li>
                            <li className="breadcrumb-item active" aria-current="page">My Address View</li>
                        </ol>
                    </nav>
                    <h2 className="breadcrumb-title">My Address View</h2>
                </div>
            </div>
        </div>
    </div>
    <div className="content">
        <div className="container-fluid">
            <div className="row">
                <div className="col-md-5 col--lg-4 col-xl-3 theiaStickySidebar">
                <StickyBox offsetTop={20} offsetBottom={20}>
                    <DashboardSidebar/>
                </StickyBox>
               </div>

               <div className="col-md-7 col-lg-8 col-xl-9">
<div className="card">
<div className="card-body">
    
 
  
        <div className="row form-row">
           
        {/* <div className="col-12 col-md-6">
                        <div className="form-group">
                          <label>Name</label>
                          <input
                            type="text"
                            className="form-control"
                           value={dataValue.member_name}
                            //placeholder="Enter address holder name"
                            disabled="disabled"
                            // defaultValue={`Address Holder Name ${address_id}`}
                            // disabled="disabled"
                            
                          />
                        </div>
                        
                      </div>

                      <div className="col-12 col-md-6">
                        <div className="form-group">
                          <label>House Number</label>
                          <input
                            type="text"
                            // max={6}
                            className="form-control"
                            // placeholder="Enter house number"
                            value={dataValue.house_number}
                            // disabled="disabled"
                            // defaultValue={`Address Holder Name ${address_id}`}
                            // disabled="disabled"
                            
                          />
                        </div>
                        
                      </div>
                     {/* <div disabled> */}
                     <div className="col-12 col-md-6">
                  <div className="form-group">
                    <label>City </label>
                    <input
                            type="text"
                            className="form-control"
                            value={dataValue.city}
                            
                            disabled="disabled"
              
                          />
                    
                  </div>
                  
                
                  
                </div>
                <div className="col-12 col-md-6">
                  <div className="form-group">
                    <label>Area </label>
                    <input
                            type="text"
                            className="form-control"
                            value={dataValue.area}
                           
                            disabled="disabled"
                         
                          />
                    
                  </div>
                 
                    
                </div>
                       <div className="offset-1 col-md-10 map mb-2" >
              <div id="map" className="map-listing">
                <div style={{height: "100px", width: "100%"}} >
                
                 
                  <MapView
                    setLatitude={setLatitiudeFnc}
                    setLongitude={setLongitudeFnc}
                    setFormattedAddress={formattedAddressFnc}
                    setPinCode={pinCodeFnc}
                    setStateValue={stateCntFnc}
                    setHouseNumber={houseNumberFnc}
                    setStreetName={streetNameFnc}
                    lat={lat}
                    lng={lng }
                    priority={"0"}
                    // center={{lat:13.941127421707419, lng:75.57465668236651}}
                     center={{lat:12.9666, lng:77.530376}}
                   // center={{lat:12.925453,lng:77.546761}}
                     gestureHandling="none"
                  
         >
          {/* 12.925453, 77.546761 */}
                   
                    {/* <Marker icon={{url: Iconmap}} coordinate = {{latitude: 12.9666,longitude:77.530376 }}
                    pinColor = {"purple"}
                    title={"akshay b a"}
                    description={"description"}/> */}
                </MapView>
                  
                </div>
              </div>
            </div>
            
                      <div className="col-12 col-md-6">
                        <div className="form-group">
                          <label>Street Name</label>
                          <textarea
                            type="text"
                            rows={1}
                            className="form-control"
                            // placeholder="Enter street name"
                             value={dataValue.street_name}
                           
                            disabled="disabled"
                            // defaultValue={`Address Holder Name ${address_id}`}
                            // disabled="disabled"
                           
                          />
                        </div>
                        
                      </div>
{/* 
                      <div className="col-12 col-md-6">
                        <div className="form-group">
                          <label>Area</label>
                          <input
                            type="text"
                            // max={6}
                            className="form-control"
                            //placeholder="Enter area"
                            value={dataValue.area}
                            disabled="disabled"
                            // defaultValue={`Address Holder Name ${address_id}`}
                            // disabled="disabled"
                            
                          />
                        </div>
                      </div> */}
                      <div className="col-12 col-md-6">
                        <div className="form-group">
                          <label>Landmark</label>
                          <input
                            type="text"
                            // max={6}
                            className="form-control"
                            // placeholder="Enter landmark"
                            // value={<input
                            //   type="text"
                            //   className="form-control"
                              value={dataValue.landmark}
                               placeholder="Enter address holder name"
                               onChange={(event) => onChangeMemberName(event)}
                   onBlur={(event) =>onBlurMemberName(event)}
                             
                              // defaultValue={`Address Holder Name ${address_id}`}
                              // disabled="disabled"
                              
                            
                            disabled="disabled"
                            // defaultValue={`Address Holder Name ${address_id}`}
                            // disabled="disabled"
                            
                          />
                        </div>
                      
                      </div>

                      {/* <div className="col-12 col-md-6">
                        <div className="form-group">
                          <label>City</label>
                          <input
                            type="text"
                            // max={6}
                            className="form-control"
                            // placeholder="Enter city"
                            value={dataValue.city}
                            disabled="disabled"
                            // defaultValue={`Address Holder Name ${address_id}`}
                            // disabled="disabled"
                            
                          />
                        </div>
                        
                      </div> */}
                     
                      <div className="col-12 col-md-6">
                        <div className="form-group">
                          <label>State</label>
                          <input
                            type="text"
                            // max={6}
                            className="form-control"
                            // placeholder="Enter state"
                            value={dataValue.state}
                            disabled="disabled"
                            // defaultValue={`Address Holder Name ${address_id}`}
                            // disabled="disabled"
                            
                          />
                        </div>
                        
                      </div>

                      <div className="col-12 col-md-6">
                        <div className="form-group">
                          <label>Pincode</label>
                          <input
                            type="text"
                            maxLength={6}
                            className="form-control"
                            // placeholder="Enter pincode"
                            value={dataValue.pincode}
                            disabled="disabled"
                            // defaultValue={`Address Holder Name ${address_id}`}
                            // disabled="disabled"
                            
                          />
                        </div>
                        
                      </div>

                      <div className="col-12 col-md-6">
                        <div className="form-group">
                          <label>Address Type</label>
                          <div className="row ml-2">
                            {optionsRdBtn.map((options, idxo) => (
                              <div
                                key={idxo}
                                className="text-primary col-md-3"
                                id={idxo}
                              >
                                <input
                                  className="form-check-input mr-2"
                                  type="radio"
                                  id={`optnRd[${idxo}]`}
                                  value={options}
                                  name={`optnameVal`}
                                  checked={addressType === options}
                                  // checked={
                                  //   radioBtn == idxo
                                  //     ? true
                                  //     : false
                                  // }
                                //   onChange={(event) => {
                                //     handleRadioOptn(event);
                                //   }}
                                />
                                <label>{options}</label>
                              </div>
                            ))}
                          </div>
                          
                      </div>
                      
                      </div>
                     
                     
                      <div className="col-12 col-md-6">
                        {/* <div className="form-group">
                          <label>Mobile</label>
                          <input
                            type="text"
                            maxLength={10}
                            // defaultValue="+91 9876543210"
                            // placeholder="Enter mobile number..."
                            value={`+91 ${dataValue.mobile}`}
                            className="form-control"
                            disabled="disabled"
                            
                          />
                        </div> */}
                        
                      </div>
                      
            <div className="submit-section">
              
                    <button type="button" className="btn btn-primary submit-btn"  onClick={handleBack}>Back</button>
                </div>
            
        </div>
      
 
   
    
</div>
</div>
</div>
            </div>
        </div>
    </div>      
    </div>
);
        }


export default MyAddressView
