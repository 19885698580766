import React,{ useState, useEffect  } from 'react';

import { useParams } from "react-router-dom";
import {useFormik} from "formik";
import * as Yup from 'yup';
import Select from "react-select";
import {connect} from "react-redux";
import {useHistory} from "react-router";
import moment from "moment";
import DashboardSidebar from '../../sidebar/sidebar.jsx';
import IMG01 from '../../../../../assets/images/placeholderImg300x300.png';
import StickyBox from "react-sticky-box";
// import DatePicker from "react-datepicker";
import { makeStyles } from '@material-ui/core/styles';
import TextField from '@material-ui/core/TextField';
import {API_URL_ROOT} from "../../../../../redux-services/api/api.js";
import {update_family_member_details_request} from "../../../../../redux-services/actions/action.familymember/action.function.js";
import toaster from "../../../../../utils/ReactTostify";
// import { Select } from '@material-ui/core';
const optionsRdBtn = ["Male", "Female"];

const options = [
  { value: 'self', label: 'self' },
  { value: 'father', label: 'father' },
  { value: 'mother', label: 'mother' },
  { value: 'wife', label: 'wife' },
  { value: 'husband', label: 'husband' },
  { value: 'childern', label: 'children' },
  { value: 'uncle', label: 'uncle' },
  { value: 'other', label: 'other' },
 
];

function FamilyMemberEdit(props) {
    // const phoneVal = /^((\\+[1-9]{1,4}[ \\-]*)|(\\([0-9]{2,3}\\)[ \\-]*)|([0-9]{2,4})[ \\-]*)*?[0-9]{3,4}?[ \\-]*[0-9]{3,4}?$/;
    const phoneVal = new RegExp(/^[6-9]{1}[0-9]{9}$/);
    const emailTest = new RegExp(/^[^\s@]+@[^\s@]+\.[^\s@]{2,}$/i);
    const [gender, setGender] = useState("");
 
  const [memberImage, setMemberImage] = useState(null);
  const [memberImageState, setMemberImageState] = useState(false);
  const [firstName, setFirstName] = useState("");
  const [lastName, setLastName] = useState("");
  const [email, setEmail] = useState("");
  const [relation, setRelation] = useState("");
  const [mobile, setMobile] = useState("");
  const [submitButton, setSubmitButton] = useState(false);

    const history = useHistory();
    const dataValue = history.location.state
    console.log("data passed form index to edit",dataValue);

    const dateValue = moment(dataValue.patient_date_of_birth).format("YYYY-MM-DD");
    const [dateOfBirth, setDateOfBirth] = useState(dateValue);
 

    useEffect(() => {
        
        setMemberImage(`${API_URL_ROOT}/${dataValue.patient_image}`);
        setDateOfBirth(moment(dataValue.patient_date_of_birth).format("YYYY-MM-DD"));
        setFirstName(dataValue.patient_firstname);
        setLastName(dataValue.patient_lastname);
        setEmail(dataValue.patient_email);
        setRelation(dataValue.relation);
        setMobile(dataValue.patient_mobile);
        if((dataValue.patient_gender == "male") || dataValue.patient_gender == "Male"){
            setGender("Male");
        }
        else if((dataValue.patient_gender == "female") || (dataValue.patient_gender == "Female")){
            setGender("Female");
        }
     
      }, []);
      const classes = useStyles();
      const handleBack = () => {
        props.history.goBack();
        
        // props.reloadFamilyMembers();
       
        
      };

      const validate = Yup.object({
            // firstname: Yup.string()
            // .max(20,'Must be 20 character or less')
            // .required('First name must not be empty'),
            // lastname: Yup.string()
            // .max(20,'Must be 20 character or less')
            // .required('Last name must not be empty'),
            dateofbirth: Yup.string().required("Date of birth is required")
            .test("is-greater", "Date of birth should not be greater than present date", function(value) {
              return moment(value,"YYYY-MM-DD").isSameOrBefore(moment().format("YYYY-MM-DD"));
            }),
            
            mobile: Yup.string().matches(phoneVal, 'phone number is not valid')
                .min(10, 'Mobile number should include 10 character')
                .max(10, 'Mobile number allowed only 10 character')
                // .test("isValid", "mobile number required", value => {
                //   if (!value) {
                //     return true;
                //   }
                // }),
                 .required('mobile number required'),
            
                email: Yup.string().matches(emailTest, 'Invalid email address'),
             //.required('Email address is required'),   
            relation: Yup.string()
            .max(20,'Must be 20 character or less')
            .required('Relation is required'), 
            
            gender: Yup.string().required('Gender must not be empty'),
            
            // .test(
            //        "invalid", 
            //       "invalid mobile number",
            //     (value) => !(value)
            //    ),
        
            memberimage:Yup.mixed(),
            
        
        })
        
    
    
      const formik = useFormik({
        initialValues: {
            "mobile":dataValue.patient_mobile,
            "dateofbirth":moment(dataValue.patient_date_of_birth).format("YYYY-MM-DD"),
            "email":dataValue.patient_email,
            "relation":dataValue.relation,
            "memberimage":dataValue.patient_image,
            "gender":dataValue.patient_gender
    
        },
        
        enableReinitialize: true,
        validationSchema: validate,
        
        onSubmit: async (values) => {
          setSubmitButton(true);
            console.log("values submission", values);
            console.log("user id ==", props.user.userId);
            var dt = formik.values;
    
            const formData = new FormData();
            formData.append('first_name', firstName);
            formData.append('last_name',lastName);
            formData.append('mobile', dt.mobile);
            formData.append('date_of_birth', dt.dateofbirth);
            formData.append('gender', dt.gender);
            formData.append('email', dt.email);
            formData.append('relation', dt.relation);
            if(memberImageState == true){
              formData.append('patient_image', dt.memberimage);
            }
           

            
            formData.append('user_id', props.user.userId);
            formData.append('patient_id', dataValue.patient_id);
            
            console.log("values submission formData", formData);
            
    
               await props.update_family_member_details(formData,(error,success)=>{
                setSubmitButton(false);
                if(error){
                  
                  console.log("error",error);
                }    
                if(success.status == 200 ){
                  toaster('success', success.message);
                    handleBack();
                 
               }
               });
              // await handleBack();
    
              //   var data = {
                
              //   first_name:dt.firstname,
              //   last_name:dt.lastname,
              //   mobile:dt.mobile,
              //   date_of_birth:dt.dateofbirth,
              //   gender:dt.gender,
              //   email:dt.email,
              //   relation:dt.relation,
              //  patient_image:dt.memberimage,
              //  user_id: props.user.userId,
              //  patient_id:dataValue.patient_id
              //      };
              //      console.log("values submission jsonData", data);
    
        },
        onReset: (values) => {}
    
    })

    console.log("values submission", formik.values);

    const handleDateOfBirth = (event) =>{
      //  const Dateset = moment(data).format("YYYY-MM-DD");
       console.log("date of birth == " ,event.target.value);
       setDateOfBirth(event.target.value);
      formik.setFieldValue("dateofbirth",event.target.value);
    }
    const handleMemberPhotoUpload = (event) =>{
      formik.setFieldValue("memberimage", event.currentTarget.files[0]);
      if(event.target.files.length !== 0){
        setMemberImage(URL.createObjectURL(event.target.files[0]));
        setMemberImageState(true);
      }
    }
    
    const handleRadioOptn = (event) =>{
        setGender(event.target.value);
      formik.setFieldValue("gender", event.target.value);
    }

//     const onChangeFirstName = (event) =>{
//       setFirstName(event.target.value);
//   }

//   const onBlurFirstName= (event) =>{
//     formik.setFieldValue("firstname",event.target.value);
// }

// const onChangeLastName = (event) =>{
//   setLastName(event.target.value);
// }

// const onBlurLastName= (event) =>{
// formik.setFieldValue("lastname",event.target.value);
// }

const onChangeMobile = (event) =>{
  setMobile(event.target.value);
}

const onBlurMobile= (event) =>{
formik.setFieldValue("mobile",event.target.value);
}

const onChangeEmail = (event) =>{
  setEmail(event.target.value);
}

const onBlurEmail= (event) =>{
formik.setFieldValue("email",event.target.value);
}

// const onChangeRelation = (event) =>{
//   setRelation(event.target.value);
// }

// const onBlurRelation= (event) =>{
// formik.setFieldValue("relation",event.target.value);
// }
   
    return (
        <div>
        <div className="breadcrumb-bar">
        <div className="container-fluid">
            <div className="row align-items-center">
                <div className="col-md-12 col-12">
                    <nav aria-label="breadcrumb" className="page-breadcrumb">
                        <ol className="breadcrumb">
                            <li className="breadcrumb-item"><a href="/home">Home</a></li>
                            <li className="breadcrumb-item active" aria-current="page">Family Member Edit</li>
                        </ol>
                    </nav>
                    <h2 className="breadcrumb-title">Family Member Edit</h2>
                </div>
            </div>
        </div>
    </div>
    <div className="content">
        <div className="container-fluid">
            <div className="row">
                <div className="col-md-5 col--lg-4 col-xl-3 theiaStickySidebar">
                <StickyBox offsetTop={20} offsetBottom={20}>
                    < DashboardSidebar />
                </StickyBox>
               </div>

               <div className="col-md-7 col-lg-8 col-xl-9">
<div className="card">
<div className="card-body">
    
 
  <form onSubmit={formik.handleSubmit} onBlur={formik.handleBlur}>
        <div className="row form-row">
            <div className="col-12 col-md-12"> 
                <div className="form-group">
                    <div className="change-avatar">
                        <div className="profile-img">
                        { memberImageState?(
                      <img
                      src={(memberImage != null)?memberImage:null}
                      className="w-25 h-25"
                       alt={"member photo"}
                      />
                    ):
                    (<img src={(memberImage != null)?memberImage:IMG01} alt="User" />)

                    }
                        </div>
                        <div className="upload-img">
                            <div className="change-photo-btn">
                                <span><i className="fa fa-upload"></i> Upload Photo</span>
                                <input type="file" className="upload" onChange={(event) => handleMemberPhotoUpload(event)}/>
                            </div>
                            <small className="form-text text-muted">Allowed JPG, GIF or PNG. Max size of 2MB</small>
                        </div>
                    </div>
                </div>
            </div>
            <div className="col-12 col-md-6">
                        <div className="form-group">
                          <label>First Name</label>
                          <input
                            type="text"
                            className="form-control"
                            value={firstName}
                            // placeholder="Enter first name ..."
                            // defaultValue={`Family Member Name ${member_id}`}
                             disabled="disabled"
                //             onChange={(event) => onChangeFirstName(event)}
                //  onBlur={(event) =>onBlurFirstName(event)}
                          />
                        </div>
                        {/* {
            formik.touched.firstname && formik.errors.firstname ? (
              <div
                style={{
                  color: "red"
                }}
              >
                {formik.errors.firstname}
              </div>
            ) : null} */}
                      </div>
                      <div className="col-12 col-md-6">
                        <div className="form-group">
                          <label>Last Name</label>
                          <input
                            type="text"
                            className="form-control"
                            value={lastName}
                            // placeholder="Enter last name ..."
                            // defaultValue={`Family Member Name ${member_id}`}
                             disabled="disabled"
                //             onChange={(event) => onChangeLastName(event)}
                //  onBlur={(event) =>onBlurLastName(event)}
                          />
                        </div>
                        {/* {
            formik.touched.lastname && formik.errors.lastname ? (
              <div
                style={{
                  color: "red"
                }}
              >
                {formik.errors.lastname}
              </div>
            ) : null} */}
                      </div>
                     
                      <div className="col-12 col-md-6">
                        <div className="form-group">
                          <label>Gender</label>
                          <div className="row ml-2">
                            {optionsRdBtn.map((options, idxo) => (
                              <div
                                key={idxo}
                                className="text-primary col-md-6"
                                id={idxo}
                              >
                                <input
                                  className="form-check-input mr-2"
                                  type="radio"
                                  id={`optnRd[${idxo}]`}
                                  value={options}
                                  name={`optnameVal`}
                                  checked={gender === options}
                                  // checked={
                                  //   radioBtn == idxo
                                  //     ? true
                                  //     : false
                                  // }
                                  onChange={(event) => {
                                    handleRadioOptn(event);
                                  }}
                                 

                                />
                                <label>{options}</label>
                              </div>
                            ))}
                          </div>
                          
                      </div>
                      {
            formik.touched.gender && formik.errors.gender ? (
              <div
                style={{
                  color: "red"
                }}
              >
                {formik.errors.gender}
              </div>
            ) : null}
                      </div>
                      <div className="col-12 col-md-6">
                        <div className="form-group">
                          <label>Relation</label>

                          <Select
                            placeholder={relation}
                            value={relation}
                            options={options}
                             defaultValue={dataValue.relation}
                            //  onChange={(event) =>onChangeRelation(event)}
                            //  onBlur={(event) =>onBlurRelation(event)}
                             onChange={(event) => {
                              setRelation(event);
                              formik.setFieldValue("relation",event.value)   
                            }}
                           

                           
                          
                          
                          />



                          {/* <input
                            type="text"
                            placeholder="Enter relation ..."
                            value={relation}
                            // defaultValue="Relation"
                            className="form-control"
                            // disabled="disabled"
                            onChange={(event) =>onChangeRelation(event)}
                 onBlur={(event) =>onBlurRelation(event)}
                          /> */}
                        </div>
                        {
            formik.touched.relation && formik.errors.relation ? (
              <div
                style={{
                  color: "red"
                }}
              >
                {formik.errors.relation}
              </div>
            ) : null}
                         

                          
                        </div>

                      <div className="col-12 col-md-6">
                      <div className="form-group">
                            <label>Date of Birth</label>
                            
                              <div className="">
                                {/* <input type="text" className="form-control datetimepicker" defaultValue="24-07-198" */}
                                {/* <DatePicker
                                  className="form-control datetimepicker"
                                //   dateFormat="yyyy-mm-dd"
                                  selected={dateOfBirth}
                                  onChange={(date) => handleDateOfBirth(date)}
                                /> */}
                                <TextField
        id="date"
        type="date"
        defaultValue={dateOfBirth}
        className={classes.textField }
        InputLabelProps={{
          shrink: true,
        }}
        onChange={(date) => handleDateOfBirth(date)}
      />
                              </div>
                            </div>
                            {
            formik.touched.dateofbirth && formik.errors.dateofbirth ? (
              <div
                style={{
                  color: "red"
                }}
              >
                {formik.errors.dateofbirth}
              </div>
            ) : null}
                          </div>
                          <div className="col-12 col-md-6">
                        <div className="form-group">
                          <label>Mobile</label>
                          <input
                            type="text"
                            // defaultValue="+91 9876543210"
                            className="form-control"
                            value={mobile}
                            name="mobile"
                            placeholder="Enter mobile number here ..."
                            onChange={(event) => onChangeMobile(event)}
                 onBlur={(event) =>onBlurMobile(event)}
                //  onChange={formik.handleChange}
                //  value={formik.values.mobile}
                          />
                        </div>
                        {
            formik.touched.mobile && formik.errors.mobile ? (
              <div
                style={{
                  color: "red"
                }}
              >
                {formik.errors.mobile}
              </div>
            ) : null}
                      </div>

                      <div className="col-12 col-md-6">
                        <div className="form-group">
                          <label>Email ID</label>
                          <input
                            type="email"
                            className="form-control"
                            value={email}
                            // defaultValue="keshav@gmail.com"
                            placeholder="Enter email address here .."
                            // {...formik.getFieldProps("email")}
                            onChange={(event) => onChangeEmail(event)}
                 onBlur={(event) =>onBlurEmail(event)}
                          />
                        </div>
                        {
            formik.touched.email && formik.errors.email ? (
              <div
                style={{
                  color: "red"
                }}
              >
                {formik.errors.email}
              </div>
            ) : null}
                      </div>






{/* 
            <div className="submit-section">
                    <button type="submit" className="btn btn-primary submit-btn">Save Changes</button>
                </div> */}
        </div>

        
        <div className="mx-1 row">
                        <div className="submit-section mr-3">
                          <button
                            type="submit"
                            className="btn btn-primary submit-btn"
                            disabled={submitButton}
                          >
                            Save Changes
                          </button>
                        </div>
                        <div className="submit-section">
                          <button
                            type="button"
                            className="btn btn-primary submit-btn"
                            onClick={handleBack}
                          >
                            Back
                          </button>
                        </div>
                      </div>


      
        </form>
   
    
</div>
</div>
</div>
            </div>
        </div>
    </div>      
    </div>
);
}

const useStyles = makeStyles((theme) => ({
    container: {
      display: 'flex',
      flexWrap: 'wrap',
    },
    textField: {
      marginLeft: theme.spacing(1),
      marginRight: theme.spacing(1),
      width: 200,
    },
  }));

  const mapDispatchToProps = (dispatch) => {
    return {
        update_family_member_details: (data,callback) =>dispatch(update_family_member_details_request(data,callback)),
    };
  };
  const mapStateToProps = (state) => {
    return {
      status: state.user.status,
      error: state.user.error,
      all_family_member: state.familymember.all_family_member,
      user_by_id: state.user.user_by_id,
      user: state.user.user,
      isLoggedIn: state.user.isLoggedIn,
      requesting: state.user.requesting
    };
  };
  export default connect(mapStateToProps, mapDispatchToProps)(FamilyMemberEdit);
