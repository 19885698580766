/** write your api's here and export to respective actions */

/** write your api's here and export to respective actions */
import { config_file } from "../../utils/env_config";

//export const API_URL_ROOT ="http://127.0.0.1:4000"
export const API_URL_ROOT = config_file[process.env["NODE_ENV"]].API_DOMAIN_NAME;
export const RAZORPAY_KEY_ID_TEST = config_file[process.env["NODE_ENV"]].RAZORPAY_KEY_ID_TEST;
export const RAZORPAY_KEY_ID = config_file[process.env["NODE_ENV"]].RAZORPAY_KEY_ID_LIVE;
export const GOOGLE_MAP_API_KEY = config_file[process.env["NODE_ENV"]].GOOGLE_MAP_API_KEY;
export const REACT_APP_VAPID_KEY = config_file[process.env["NODE_ENV"]].REACT_APP_VAPID_KEY;
// {console.log("apivalueis",`${API_URL_ROOT}/logo.png`)};
export const API_URL ={
    // Logo

     LOGO:`${API_URL_ROOT}/logo.png`,
    // Registration module 
    SEND_OTP:`${API_URL_ROOT}/user/sendotp`,
    RESEND_OTP:`${API_URL_ROOT}/user/resendotp`,
    VERIFY_OTP:`${API_URL_ROOT}/user/verifyotp`,
    // LOGIN MODULE
    LOGIN:`${API_URL_ROOT}/user/login`,
    // PACKAGE MODULE
    ALL_PACKAGE:`${API_URL_ROOT}/user/getpackage`,
    ALL_POPULAR_PACKAGE:`${API_URL_ROOT}/user/popularpackage`,
    // TESTS
    ALL_TEST:`${API_URL_ROOT}/user/getservice`,
    ALL_POPULAR_TEST:`${API_URL_ROOT}/user/popularservice`,
    // CATEGORY
    ALL_CATEGORY:`${API_URL_ROOT}/user/getcategory`,
    // CHANGE MPIN
    CHANGE_MPIN:`${API_URL_ROOT}/user/changepin`,
    // ARTICLES
    GET_ALL_ARTICLES:`${API_URL_ROOT}/user/getarticle`,
    VIEW_ARTICLE:`${API_URL_ROOT}/user/viewarticle`,

    // FAQ
    ALL_FAQ:`${API_URL_ROOT}/user/getfaq`,

    // FAMILY MEMBERS
    ADD_FAMILY_MEMBER :`${API_URL_ROOT}/user/addpatient`,
    ALL_FAMILY_MEMBERS:`${API_URL_ROOT}/user/getpatient/`,
    UPDATE_FAMILY_MEMBERS:`${API_URL_ROOT}/user/updatepatient`,
    DELETE_FAMILY_MEMBERS:`${API_URL_ROOT}/user/deletepatient`,

    // ADDRESS
    ADD_ADDRESS:`${API_URL_ROOT}/user/addpatientaddress`,
    ALL_ADDRESS:`${API_URL_ROOT}/user/getpatientaddress/`,
    UPDATE_ADDRESS:`${API_URL_ROOT}/user/updatepatientaddress`,
    DELETE_ADDRESS:`${API_URL_ROOT}/user/deletepatientaddress`,
    GET_ALL_CITY:`${API_URL_ROOT}/user/getcity`,
    GET_ALL_AREA_BY_CITY_ID:`${API_URL_ROOT}/user/getareaincity`,


    // TIMESLOTS
    ALL_TIME_SLOT:`${API_URL_ROOT}/order/timeslot`,
    
    //COUPONS
    ALL_COUPONS:`${API_URL_ROOT}/order/coupon`,
    VALIDATE_COUPON:`${API_URL_ROOT}/order/validatecoupon`,


    // ORDER
    CREATE_ORDER:`${API_URL_ROOT}/order/createOrder`,
    VERIFY_ORDER:`${API_URL_ROOT}/order/verifyOrder`,
    GET_ALL_ORDERS:`${API_URL_ROOT}/user/getorder`,
    GET_ORDER_DETAILS_BY_ORDER_ID:`${API_URL_ROOT}/user/getorderdetailswithorderid`,
    CANCEL_ORDER_BY_ORDER_ID:`${API_URL_ROOT}/order/cancelOrder`,
    ORDER_INVOICE_BY_ORDER_ID:`${API_URL_ROOT}/user/invoice`,
    ORDER_FAILED:`${API_URL_ROOT}/order/paymentfailed`,

    //DELIVERY CHARGES
    GET_DELIVERY_CHARGES:`${API_URL_ROOT}/user/getcustomerdeliverycharge`,

    // USER PROFILE
    VIEW_PROFILE:`${API_URL_ROOT}/user/viewprofile`,
    UPDATE_PROFILE:`${API_URL_ROOT}/user/editprofile`,

    // Dashboard
    BOOK_ON_CALL:`${API_URL_ROOT}/user/bookoncall`,
    GET_ALL_PRESCRIPTIONS:`${API_URL_ROOT}/user/getalluserprescriptionByUserId`,
    UPLOAD_PRESCRIPTION:`${API_URL_ROOT}/user/uploadprescription`,
    DELETE_UPLOADPRESCRIPTION :`${API_URL_ROOT}/user/deleteuploadprescription`,

    // Support
    GET_ALL_SUPPORT:`${API_URL_ROOT}/user/getAllSupport`,
    CREATE_SUPPORT:`${API_URL_ROOT}/user/createSupport`,
    CREATE_SUPPORT_CONVERSATION_BY_TICKET_ID:`${API_URL_ROOT}/user/createconversation`,
    GET_SUPPORT_CONVERSATION_BY_TICKET_ID:`${API_URL_ROOT}/user/getallconversation`,

    // Feedback
    GET_ALL_FEEDBACK_QUESTION:`${API_URL_ROOT}/user/getallfeedbackquestion`,
    CREATE_FEEDBACK:`${API_URL_ROOT}/user/feedbackrating`,

    // Push-Notification
    POST_FCM_TOKEN:`${API_URL_ROOT}/notification/fcm`,

    // Custom-Notification
    GET_CUSTOM_NOTIFICATION:`${API_URL_ROOT}/notification/getcustomnotification`,
    POST_CUSTOM_NOTIFICATION_READ_STATUS_BY_ID:`${API_URL_ROOT}/notification/updatereadstatus`,

    //logout 
     VERIFY_LOGOUT:`${API_URL_ROOT}/user/logout`,

     // forgetMpin
     FORGET_MPIN_SEND_OTP:`${API_URL_ROOT}/user/forgotpin/sendotp`,
     VERIFY_FORGET_MPIN:`${API_URL_ROOT}/user/resetpin/verifyotp`,


}


// login module 
// export const login_user = "/user/login";
// export const logout_user = "/user/logout";

