import { put, call, select, takeEvery, takeLatest } from 'redux-saga/effects';
import { createBrowserHistory } from "history";
import { 
    login_req, 
    login_fail, 
    login_success,

    logout_success,
    logout_req,
    logout_fail,

    get_user_by_id_fail,
    get_user_by_id_success,
    
    update_user_details_request,
    update_user_details_success,
    update_user_details_error,
 
    
  } from './action.function.js';

  import { 
    login_user,  
    API_URL,
    } from '../../api/api.js';
    import {persistor} from '../../store/create.store.js';
    import {isLoading} from '../action.loader/action.function.js';
    import * as constance from "../constance/action.constance";
  import decodeJwt from "jwt-decode";
  
  import { 
      ADD_TOKEN_TO_STATE, 
      LOGIN_REQUEST,
      LOGOUT_REQUEST,
      LOGOUT_SUCCESS,
      QUERYING_USER_BY_TOKEN,
      QUERYING_USER_BY_TOKEN_ERROR, 
      ADD_TOKEN_TO_STATE_REQUEST,
      QUERYING_USER_BY_TOKEN_SUCCESS ,
      // update
      UPDATE_USER_DETAILS_REQUEST,
      // user profile 
      GET_USER_BY_ID_REQUEST
  } from './action.type';
  
  import {API} from '../../../utils/networkApi';
  import {  post} from '../../../utils/apiRequest';
  
  import { push } from "connected-react-router"
  import { toast } from "react-toastify"
  
  import errorPage from '../../../utils/RedirectErrorPage';
  import toaster from "../../../utils/ReactTostify";
  


const history = createBrowserHistory();
function* loginSaga(payload) {
    try {
       console.log("data value of saga ========",payload.data);
     
       yield put(isLoading(true));
      // let Login_Api_Call = API.post(API_URL.LOGIN)
      let response = yield call(API.post,API_URL.LOGIN,payload.data);
      yield put(isLoading(false));
      //console.log("resposne saga --------------",response);
      const  user  = response.data;
      // console.log("user values === ", user.message);
      if(user.status === 200){
        localStorage.setItem("token", user.token);
      // addTokenToState();
      // yield put(addTokenToState());
      yield put(login_success(user));
      yield put({ type: ADD_TOKEN_TO_STATE_REQUEST })
      payload.callback(null,user)
      toaster('success', user.message);
      }
      if(user.status === 401){
         toaster('error', user.message);
      }
      
      // history.push("/users/dashboard");
      // yield put(addTokenToState());
     
      
    }
    catch (error) {
      yield put(isLoading(false));
      payload.callback(error,null);
      yield put(login_fail(error));
      
        // toaster('error', error);
    }
  }
  
  function* addTokenToStateSaga() {
      let token
    try {
      token = localStorage.getItem("token")
      console.log("token add to state ", token)
    
    // If no token, bail out:
    if (!token){
      return yield put({ type: ADD_TOKEN_TO_STATE, payload: token })
    } 
    
    // Use token to check DB for user:
    // checkDbForUser(token)
    yield put({ type: QUERYING_USER_BY_TOKEN, payload: token })
  }catch (e) {
      console.log("ADD TOKEN TO STATE ERROR:", e)
    }
  
  }

    // user access based on userid
    function* checkDbForUserSaga(payload){
      try{
        
        console.log("payload values for checkDB for User", payload);
        const { userId, exp } = decodeJwt(payload.payload);
        // const decode = decodeJwt(payload.payload);
        // console.log("decode jwt =====",decode);
        // console.log("decoded userId ======", userId);
        // console.log("decoded exp ========", exp);
        if (!userId)
          return yield put({
            type: QUERYING_USER_BY_TOKEN_ERROR,
            payload: `Token invalid: ${payload.payload}`
          })
        if (Date.now() >= (exp*1000)) {
          // console.log('--------token and userId-----',payload.payload, userId);
          localStorage.removeItem('token');
          persistor.purge();
          history.push("/home/login");
          window.location.reload();
       

        }
      console.log('--------token and userId-----',payload.payload, userId);
      }catch (e) {
        console.log("QUERYING_USER_BY_TOKEN_ERROR:", e)
      }
     
  }


  // USER PROFILE


  function* getUserByIdSaga(payload) {
    const {data,callback} =payload
    //  console.log("data sent to user by id saga", data)
      try {
        yield put(isLoading(true));
        let result = yield call(API.get,API_URL.VIEW_PROFILE,API.getHeaders());
        // let response = await result.json();
        yield put(isLoading(false));
        let response = result.data;
         console.log("get user by id response",response);
        yield put(get_user_by_id_success(response))
          callback(null,response);
      } catch (error) {
        yield put(isLoading(false));
        yield put(get_user_by_id_fail(error));
        callback(error,null);
        toaster("error", error);
        
      }
    };



    function* updateUserSaga(payload) {
      const { data, callback } = payload;
  console.log("requesting update user saga=======", data);
      try {
        yield put(isLoading(true));
          const response = yield call(API.post,API_URL.UPDATE_PROFILE,data,API.getHeadersFormData());
          yield put(isLoading(false));
        const result  = response.data;
        console.log("update user response saga",result);
        yield put(update_user_details_success(result))
          callback(null, result);
         
  
  
      } catch (error) {
        yield put(isLoading(false));
        yield put(update_user_details_error(error));
        callback(error,null);
        toaster("error", error);
  
      }
  }



  function* logoutSaga() {
    try {
      // const history = createBrowserHistory();
      yield put(isLoading(true));
      localStorage.removeItem('token');
      localStorage.clear();
      //  persistor.purge();
      //  persistor.purge();

      // persistor.purge().then(() => {
      //   return persistor.flush();
      // }).then(() => {
      //   persistor.pause();
      // });

      persistor.pause();
    persistor.flush().then(() => {
      return persistor.purge();
    });
      yield put(logout_success());
      yield put(isLoading(false));
      history.push("/home/login");
      window.location.reload();
      yield put(isLoading(false));
        //localStorage.removeItem("token")
        toast.success("Log out successful", {
          position: toast.POSITION.BOTTOM_RIGHT
        })
 
      // yield put(logout_success());
      // yield put(addTokenToState());
     
     
    }
    catch (error) {
      yield put(isLoading(false));
      yield put(logout_fail(error));
        toaster('error', error);
        history.push("/home/login");
        window.location.reload();
    }
  }
  


export default function* userSaga() {
  
    yield takeEvery(LOGIN_REQUEST, loginSaga);
    yield takeEvery(LOGOUT_REQUEST, logoutSaga);
    yield takeEvery(ADD_TOKEN_TO_STATE_REQUEST, addTokenToStateSaga);
    yield takeEvery(QUERYING_USER_BY_TOKEN, checkDbForUserSaga);

    // user profile 
    yield takeEvery(GET_USER_BY_ID_REQUEST, getUserByIdSaga);

    // update
    yield takeEvery(UPDATE_USER_DETAILS_REQUEST, updateUserSaga);
    
   


}